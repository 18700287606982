import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { KodModule } from '@kod';
import { KodConfigModule } from '@kod/services/config';
import { KodMockApiModule } from '@kod/lib/mock-api';
import { CoreModule } from '@app/core/core.module';
import { appConfig } from '@app/core/config/app.config';
import { mockApiServices } from '@app/mock-api';
import { LayoutModule } from '@app/layout/layout.module';
import { AppComponent } from '@app/app.component';
import { appRoutes } from '@app/app.routing';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SecurityCheckInbound } from '@lib/security/security.inbound.interceptor';
import { SecurityCheckOutbound } from '@lib/security/security.outbound.interceptor';



const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports     : [
      BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
      HttpClientModule,
      FormsModule,

      BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Kod, KodConfig & KodMockAPI
        KodModule,
        KodConfigModule.forRoot(appConfig),
        KodMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot

    ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SecurityCheckInbound, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SecurityCheckOutbound, multi: true },
  ],
    bootstrap   : [
        AppComponent
    ]
})
export class AppModule
{
}
