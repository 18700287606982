/* tslint:disable:max-line-length */
import { KodNavigationItem } from '@kod/components/navigation';


export const appNavigation: KodNavigationItem[] = [
  {
    id   : 'd.og.ov',
    title: 'Overview',
    type : 'basic',
    icon : 'heroicons_outline:light-bulb',
    link : '/by/overview'
  },
  {
    id   : 'd.og.ov.lang',
    title: 'Browse By Language',
    type : 'basic',
    icon : 'heroicons_outline:variable',
    link : '/by/languages'
  },
  {
    id : 'd.og.ov.frmwrk',
    title : 'Browse By Frameworks',
    type : 'basic',
    icon : 'heroicons_outline:squares-plus',
    link : '/by/frameworks'
  },
  {
    id      : 'd.og.Gen.type',
    title   : 'Browse By Project Type',
    subtitle: 'Standalone, Microservices, etc.',
    type    : 'collapsable',
    icon    : 'heroicons_outline:wrench-screwdriver',
    children: [
      {
        id   : 'd.og.data',
        title: 'Data Sciences',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/by/type/data'
      },
      {
        id   : 'd.og.desktop',
        title: 'Desktop',
        type : 'basic',
        icon : 'heroicons_outline:computer-desktop',
        link : '/by/type/desktop'
      },
      {
        id   : 'd.og.microservice',
        title: 'Microservices',
        type : 'basic',
        icon : 'heroicons_outline:cpu-chip',
        link : '/by/type/microservices'
      },
      {
        id   : 'd.og.mobile',
        title: 'Mobile',
        type : 'basic',
        icon : 'heroicons_outline:device-phone-mobile',
        link : '/by/type/mobile'
      },
      {
        id   : 'd.og.scripts',
        title: 'Scripts',
        type : 'basic',
        icon : 'heroicons_outline:document-text',
        link : '/by/type/scripts'
      },
      {
        id   : 'd.og.standalone',
        title: 'Standalone',
        type : 'basic',
        icon : 'heroicons_outline:viewfinder-circle',
        link : '/by/type/standalone'
      },
      {
        id   : 'd.og.web',
        title: 'Web',
        type : 'basic',
        icon : 'heroicons_outline:globe-alt',
        link : '/by/type/web'
      }
    ]
  }
  ];

export const compactNavigation: KodNavigationItem[] = [
...appNavigation
];
export const futuristicNavigation: KodNavigationItem[] = [
...appNavigation
];
export const horizontalNavigation: KodNavigationItem[] = [
...appNavigation
];
export const defaultNavigation: KodNavigationItem[] = [
...appNavigation
];
