import { NgModule } from '@angular/core';
import { KodPlatformService } from '@kod/services/platform/platform.service';

@NgModule({
    providers: [
        KodPlatformService
    ]
})
export class KodPlatformModule
{
    /**
     * Constructor
     */
    constructor(private _kodPlatformService: KodPlatformService)
    {
    }
}
