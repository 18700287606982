import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { ODataQueryOptions } from './app-http-client.interface';

export function appHttpClientCreator(httpHandler: HttpHandler) {
    return new AppHttpClient(httpHandler);
}

@Injectable({ providedIn: 'root' })
export class AppHttpClient extends HttpClient {


    private result: any;
    constructor(
        public httpHandler: HttpHandler,
        ) {
          super(httpHandler)
    }


    public DownloadFile(data: any, fileName: string, type: string = 'application/vnd.ms-excel') {
        var blob = new Blob([data], { type: type });
        var url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('style', 'display: none;')
        document.body.appendChild(a);
        a.setAttribute("href", url);
        a.setAttribute("download", fileName);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    /**
   * GET request with query parameters. If no query parameters are necessary, use 'get' instead of 'Get'
   * @param {string} endPoint This does not need enviorment prefix url
   * @param {any} params Object of parameters, or single value
   * @param {any} paramName Parameter name in the API if single value is used
   * @returns {Observable<T>}
   */
    public GetBlob(endPoint: string, param?: any, paramName?: string): Observable<Blob> {
        let httpParams = new HttpParams();
        if (param != null) {
            if (typeof (param) === 'object') {
                Object.keys(param).forEach((key) => {
                    if (param[key] != null) {
                        httpParams = httpParams.append(key, param[key].toString());
                    }
                })
            } else if (paramName) {
                httpParams = httpParams.append(paramName, param.toString());
            }
        }
        //return this.get(endPoint, { params: httpParams, responseType: 'blob' });
        return this.get(endPoint, { params: httpParams, responseType: 'blob'});
    }

    /**
   * GET request with query parameters. If no query parameters are necessary, use 'get' instead of 'Get'
   * @param {string} endPoint This does not need enviorment prefix url
   * @param {any} params Object of parameters, or single value
   * @param {any} paramName Parameter name in the API if single value is used
   * @returns {Observable<T>}
   */
    public Get<T>(endPoint: string, param?: any, paramName?: string): Observable<T> {
        let httpParams = new HttpParams();
        if (param != null) {
            if (typeof (param) === 'object') {
                Object.keys(param).forEach((key) => {
                    if (param[key] != null) {
                        httpParams = httpParams.append(key, param[key].toString());
                    }
                })
            } else if (paramName) {
                httpParams = httpParams.append(paramName, param.toString());
            }
        }
        return this.get<T>(endPoint, { params: httpParams  });
    }

    /**
   * GET request with query parameters. If no query parameters are necessary, use 'get' instead of 'Get'
   * @param {string} endPoint This does not need enviorment prefix url
   * @param {any} params Object of parameters, or single value
   * @param {any} paramName Parameter name in the API if single value is used
   * @returns {Observable<T>}
   */
    public GetExtended<T>(endPoint: string, param?: any, paramName?: string): Observable<T> {
        let httpParams = new HttpParams();
        if (param != null) {
            if (typeof (param) === 'object') {
                Object.keys(param).forEach((key) => {
                    if (param[key] != null) {
                        httpParams = httpParams.append(key, param[key].toString());
                    }
                })
            } else if (paramName) {
                httpParams = httpParams.append(paramName, param.toString());
            }
        }
        return this.get<T>( endPoint, { params: httpParams });
        //return this.get<T>( endPoint, { params: httpParams });
    }

    /**
   * POST request with query parameters. If no query parameters are necessary, use 'get' instead of 'Get'
   * @param {string} endPoint This does not need enviorment prefix url
   * @returns {Observable<T>}
   */
    public Post<T>(url: string, body?: any): Observable<T> {
        //return this.post<T>(url, body);
        return this.post<T>(url, body, {  });
    }

    public PostWithOption<T>(url: string, body: any, options?: any): Observable<HttpEvent<T>> {
        options.responseType = 'json';
        // options.headers = { 'tenant': this.tenantId };
        return this.post<T>(url, body, options);
    }

    public PostExtended<T>(url: string, body?: any): Observable<T> {
        //return this.post<T>(url, body);
        return this.post<T>( url, body, {  });
    }

    /**
    * Put request with query parameters. If no query parameters are necessary, use 'get' instead of 'Get'
    * @param {string} endPoint This does not need enviorment prefix url
    * @returns {Observable<T>}
    */
    public Put<T>(url: string, body?: any): Observable<T> {
        //return this.put<T>(url, body);
        return this.put<T>(url, body, {  });
    }

    public Delete<T>(endPoint: string, param?: any, paramName?: string): Observable<T> {
        let httpParams = new HttpParams();
        if (param != null) {
            if (typeof (param) === 'object') {
                Object.keys(param).forEach((key) => {
                    if (param[key] != null) {
                        httpParams = httpParams.append(key, param[key].toString());
                    }
                })
            } else if (paramName) {
                httpParams = httpParams.append(paramName, param.toString());
            }
        }
        //return this.delete<T>(endPoint, { params: httpParams });
        return this.delete<T>(endPoint, { params: httpParams,  });
    }

    public Patch<T>(url: string, body?: any): Observable<T> {
        //return this.patch<T>(url, body);
        return this.patch<T>(url, body, {  });
    }


    // /**
    // * GET Odata request with query parameters.
    // * @param {string} endPoint This does not need enviorment prefix url
    // * @param {any} params Object of parameters, or single value
    // * @param {any} paramName Parameter name in the API if single value is used
    // * @returns {Observable<T>}
    // */
    // public GetOData<T>(endPoint: string, param?: any, paramName?: string, options?: ODataQueryOptions): Observable<T> {
    //     let httpParams = new HttpParams();
    //     if (param != null) {
    //         if (typeof (param) === 'object') {
    //             Object.keys(param).forEach((key) => {
    //                 if (param[key] != null) {
    //                     httpParams = httpParams.append(key, param[key].toString());
    //                 }
    //             })
    //         } else if (paramName) {
    //             httpParams = httpParams.append(paramName, param.toString());
    //         }
    //     }
    //     if (options != null) {
    //         Object.keys(options).forEach((key) => {
    //             if (options[key] != null) {
    //                 httpParams = httpParams.append(key, options[key].toString());
    //             }
    //         })
    //     }
    //     return this.get<T>(endPoint, { params: httpParams,  });
    // }


}
