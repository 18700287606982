import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthLibActions } from '@lib/security/auth/lib-auth-actions';
import {environment} from "@env/environment";

/**
 *  Security Interceptor (Middleware)
 *  - JwtInBound - Interceptor
 *  - Send the access token with the request
 */
@Injectable({ providedIn: 'root' })
export class SecurityCheckOutbound implements HttpInterceptor {
    constructor(private _authActs:AuthLibActions) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = this._authActs.getAccessToken();
        request = request.clone({
            setHeaders:{
                'authorization': `bearer ${token}`,
                'spark': environment.spark.origin
            }
        });
        return next.handle(request);
    }
}
