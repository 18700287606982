import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { KodCardModule } from '@kod/components/card';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExpenseModalComponent } from './extend/_modal/expense.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { KodAlertModule } from '@kod/components/alert';
import { ExpenseComponent } from './expense.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ListFloatersComponent } from './extend/list-floaters/list-floaters.component';
import { ViewFloaterComponent } from './extend/view-floater/view-floater.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {MatNativeDateModule} from "@angular/material/core";

@NgModule({
  declarations: [
    ExpenseModalComponent,
    ExpenseComponent,
    ListFloatersComponent,
    ViewFloaterComponent
  ],
  imports     : [
      MatButtonModule,
      MatTableModule,
      MatIconModule,
      MatCheckboxModule,
      MatTooltipModule,
      KodCardModule,
      CommonModule,
      MatDialogModule,
      MatFormFieldModule,
      MatInputModule,
      MatSlideToggleModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatDividerModule,
      MatProgressBarModule,
      MatProgressSpinnerModule,
      MatFormFieldModule,
      MatMenuModule,
      MatSelectModule,
      MatRadioModule,
      KodAlertModule,
      FormsModule,
      ReactiveFormsModule,
      MatSidenavModule,
  ],
  exports     : [
      ExpenseModalComponent,
      ExpenseComponent
  ],
})
export class LibExpenseModule
{
}
