import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { KodCardModule } from '@kod/components/card';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TodoComponent } from './todo.component';
import { TodoModalComponent } from './extend/modal/todo.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { KodAlertModule } from '@kod/components/alert';

@NgModule({
  declarations: [
    TodoComponent,
    TodoModalComponent,
  ],
  imports     : [
      MatButtonModule,
      MatTableModule,
      MatIconModule,
      MatCheckboxModule,
      MatTooltipModule,
      KodCardModule,
      CommonModule,
      MatDialogModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      KodAlertModule,
      FormsModule,
      ReactiveFormsModule
  ],
  exports     : [
      TodoComponent,
      TodoModalComponent,
  ],
})
export class LibTodoModule
{
}
