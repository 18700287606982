import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { kodAnimations } from '@kod/animations';
import { KodAlertType } from '@kod/components/alert';
import { UserLibService } from '@lib/core/users/lib-users';
import { AuthLibService } from '@lib/security/auth/lib-auth';

@Component({
  selector: 'modal-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  animations   : kodAnimations
})

export class UserModalComponent implements OnInit {

  userFormGroup: UntypedFormGroup;

  showAlert : boolean = false;
    alert: { type: KodAlertType; message: string } = {
        type   : 'success',
        message: ''
    };
  private _baseUrl: string;
  public _mydata: any;
  public dataReady: boolean = false;

  constructor(
    private _auth:AuthLibService,
    private _formBuilder: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<UserModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _user:UserLibService
  ) {


  }

  ngOnInit(): void {

    this._mydata = this.data;
    console.log(this._mydata);
    this.dataReady = true;

    this.userFormGroup = this._formBuilder.group({
      username: [ this._mydata.editData.username || ''],
      email: [ this._mydata.editData.email || ''],
      password: [ this._mydata.editData.password || ''],
    });
  }

  closeModal(status?:object) {
    if(status){
      this._dialogRef.close(status);
    }else{
      this._dialogRef.close();
    }
  }

  handleError(error:string) {
    this.showAlert = true;
    this.alert = {
        type   : 'error',
        message: error
    };
  }

  submit(){
    if(this.userFormGroup.invalid){
      console.log("invalid");
      console.log(this.userFormGroup)
      this.handleError('Please fill out all fields');
      return;
    }
    if(this._mydata.selection == 'new'){


this._auth.registerStrict(this.userFormGroup.value).then(res=>{
  console.log(res);
  //this.closeModal(res);
}).catch(err=>{
  console.log(err);
  this.handleError(err);
})

      // this._user.createUser(this.userFormGroup.value).then(res=>{
      //   this.closeModal({event:'save',data:res});
      // }).catch(err=>{
      //   this.handleError(err.error);
      // })
    }else if(this._mydata.selection == 'edit'){

    }
  }
}
