import { Injectable, isDevMode } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthLibService } from './lib-auth';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { AppHttpClient } from '@lib/http/app-http-client.service';
import { AuthUtils } from '../tools/auth.utils';
import { authInterfaceHelper as a } from './lib-auth-interface-helper';
import { from } from 'rxjs';

import { AppCookieService } from '@lib/tools/storage/cookie';



/**
 * Authentication Library Actions Prototype
 *  -
 *
 * (Prototype Library API)
 */
@Injectable({providedIn: 'root'})
export class AuthLibActions {


    constructor(
        private _auth:AuthLibService,
        private _cookie:AppCookieService,

        private http: AppHttpClient)
        {


        }

    private accessTokToken: string;
    private authenticated: boolean = false;
    private tokenExpireTime: number = 0;
    private codeSent: boolean = false; // change this to use local storage
    private confirmationEmail: string = "";
    private consoleLogger: boolean = false;


    private checkForToken:boolean = false;


    // -----------------------------------------------------------------------------------------------------
    // @ Private Auth Actions Methods
    // -----------------------------------------------------------------------------------------------------


    private setConfirmationEmail(email: string)
    {
        this.confirmationEmail = email;
    }


    private async setAccessToken(token:any)
    {
      this.accessTokToken = token.tokToken;
      localStorage.setItem('soFresh', 'so fresh so clean');
      localStorage.setItem('tokToken', this.accessTokToken);
      localStorage.setItem('fresh', token.freshTokToken);
    }

    private setTokensFromLocalStorage()
    {
        this.accessTokToken = localStorage.getItem('tokToken');
        this._cookie.set('tokToken', this.accessTokToken);
        this._cookie.set('fresh', localStorage.getItem('fresh'));
    }

    private setAuthenticated(authenticated:boolean):boolean
    {
        this.authenticated = authenticated;
        if(isDevMode() && this.consoleLogger)console.log("Authenticated: " + authenticated);
        return this.authenticated;
    }


    private setTwoFactorCodeSent(codeSent:boolean):void
    {
        this.codeSent = codeSent;
    }


    private startRefreshTokenTimer()
    {
        // parse json object from base64 encoded jwt token
        // console.log("startRefreshTokenTimer");
        // console.log(this.accessTokToken);

        const jwtToken = JSON.parse(atob(this.accessTokToken.split('.')[1]));
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        this.tokenExpireTime = (expires.getTime() - Date.now()) - (60 * 1000);
    }


    private stopRefreshTokenTimer()
    {
        this.tokenExpireTime = null;
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Private Auth Actions Async Methods
    // -----------------------------------------------------------------------------------------------------


    // private system call for two factor authentication
    private async getTwoFactorCode():Promise<any>
    {
        this.setTwoFactorCodeSent(true);
        return await this._auth.sendTwoFactorAuthenticationCodeStrict({channel: 'sms'}).catch(err => {
            if(isDevMode() && this.consoleLogger)console.error("Auth Lib Actions - error sending two factor code.");
            if(isDevMode() && this.consoleLogger)console.error(err);
            this.setTwoFactorCodeSent(false);
            return false;
        })
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public Auth Actions Methods
    // -----------------------------------------------------------------------------------------------------
    get accessToken(): string
    {
        return this.accessTokToken;
    }
    getConfirmationEmail()
    {
        return this.confirmationEmail;
    }

    doesFreshExists(){
      if(localStorage.getItem('soFresh') != 'so fresh so clean'){
        this.setAuthenticated(false);
        this.removeAccessToken();
        return false;
      }else{
        this.setTokensFromLocalStorage();
        return true;
      }
    }

    saveConfirmationEmail(email: string)
    {
        this.setConfirmationEmail(email);
    }


    getAccessToken():string
    {
        return this.accessTokToken;
    }


    removeAccessToken()
    {
        this.accessTokToken = null;
        localStorage.removeItem('soFresh');
        localStorage.removeItem('tokToken');
        localStorage.removeItem('fresh');
        this._cookie.remove('tokToken');
        this._cookie.remove('fresh');
    }

    isAccessTokenNull():boolean
    {
        return this.accessTokToken == null;
    }


    isUserAuthenticated():boolean
    {
        const jwtHelper = new JwtHelperService();
        return this.setAuthenticated(this.accessTokToken != null && !jwtHelper.isTokenExpired(this.accessTokToken));
    }



    isTwoFactorCodeSent():boolean
    {
        return this.codeSent;
    }


    askForAnother2FACode():Observable<any>
    {
        if(!this.codeSent)return of(false) ; // if the code has not been sent, do nothing
        // else, send the code again
        this.setTwoFactorCodeSent(false); // reset the code sent flag
        return this.requestTwoFactorCode();
    }




    // get the code for the user to authenticate
    requestTwoFactorCode():Observable<any>
    {
        // only send the code when the user asks for it
        if(this.codeSent){
            return of(false);
        }

        return of(this.getTwoFactorCode());
    }


    // This function is called from the interceptor to set the access token
    /**
     * Uses the SecurityInboudInterceptor to set the access token
     *
     */
    async evaluateAccessToken(tokenBody:any)
    {
        if (tokenBody?.tokToken && tokenBody?.freshTokToken) {
            await this.setAccessToken(tokenBody);
            this.authenticated = true; // set the authenticated flag
        }

    }


    giveTokenPayload():any
    {
        return this._auth.giveTokenPayLoad(this.accessTokToken);
    }


    tokenStart():void
    {
        this.startRefreshTokenTimer();
    }


    tokenStop():void
    {
        this.stopRefreshTokenTimer();
    }

private firstCheck:boolean = true;
    /**
     * Check the authentication status
     */
     check(): Observable<boolean>
     {
        if(isDevMode() && this.consoleLogger)console.log("Check is being called");

        if(this.doesFreshExists()){
          if(isDevMode() && this.consoleLogger)console.log("Fresh exists");
          this.accessTokToken = this._cookie.get('tokToken');
        }
        if(this.firstCheck){
          this.firstCheck = false;
          let version = async() => {
            let version = await this._auth.Version();
            if(isDevMode() && this.consoleLogger)console.log(version);
            //
            await this._auth.GenerateRoles();
          }
          version();
        }

        // Check if the user is logged in
         if ( this.authenticated )
         {
             return of(true);
         }

         // Check the access token availability
         if ( !this.accessTokToken || this.accessTokToken === 'undefined' || this.accessTokToken.length === 0 )
         {
            if(isDevMode() && this.consoleLogger)console.log("Access token is not available");
            return of(false);
         }

         // Check the access token expire date
         if ( AuthUtils.isTokenExpired(this.accessTokToken) )
         {
            if(isDevMode() && this.consoleLogger)console.log("Token Expired");
            return of(false);
         }

         // If the access token exists and it didn't expire, sign in using it
         if(isDevMode() && this.consoleLogger)console.log("Access token exists and it didn't expire, using it to sign in");

         return from(this.signInUsingToken());
     }


    // -----------------------------------------------------------------------------------------------------
    // @ Public Auth Actions Async Methods
    // -----------------------------------------------------------------------------------------------------


    async signIn(params: FormGroup): Promise<any> {
      return await this._auth.loginStrict(params).then(async res => {
        if(isDevMode() && this.consoleLogger)console.log(res);
            await this.evaluateAccessToken(res);
            this.tokenStart();
            return {success:true, data:res}; // return the response
        }).catch(err => {
            if(isDevMode() && this.consoleLogger)console.log(err);
            return {success:false, error:err}; // return the error
        });
     }



    async signOut():Promise<boolean> {
        return await this._auth.logoutStrict().then(res => {
            this.tokenStop();
            this.removeAccessToken();
            return true;
        });
    }


    /**
     * Sign in using the access token
    */
    async signInUsingToken(): Promise<boolean>
    {
       return await this._auth.getAccessTokenStrict().then(async token => {
            if(token){
                // console.log("sign in using token got a token", token);
                await this.evaluateAccessToken(token);
                return true;
            }else{
                if(isDevMode() && this.consoleLogger)console.log('No token found');
                return false;
            }
        }).catch(err => { if(isDevMode() && this.consoleLogger)console.log(err); return false; });
    }


    /**
    * Check if two factor code is valid
    */
    async twoFactorCodeIsValid(params: a['VTFP']):Promise<boolean>
    {
      return await this._auth.verifyTwoFactorAuthenticationCodeStrict(params).then((response) => {
        const model = response as unknown as a['VTFAR'];
        if (model.isValid) {
            return true;
        } else {
            return false;        }
      }).catch(error => {
        if(isDevMode() && this.consoleLogger)console.log(error);
        return false;
      });
    }


    async giveUser(){
      return; // Function was deleted ..
        // return await this._user.getUserProfileStrict();
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public Auth Lib Wrapper Methods
    // -----------------------------------------------------------------------------------------------------


    async sendConfirmEmail(params:a['ECP'])
    {
        await this._auth.sendEmailConfirmationStrict(params);
    }

    async confirmEmail(params:a['VECP'])
    {
        return await this._auth.confirmEmailStrict(params);
    }

    async register(params:a['RP'])
    {
        return await this._auth.registerStrict(params);
    }

    async doesEmailExist(params:string)
    {
        return await this._auth.emailExistsStrict(params);
    }

    async setPhonePwd(params:a['SPPP'])
    {
        await this._auth.setPhonePasswordStrict(params);
    }

    async getTwoFactorData()
    {
        return await this._auth.getTwoFactorModelStrict().catch(err => {
            if(isDevMode() && this.consoleLogger)console.log(err);
            return null;
        });
    }
}
