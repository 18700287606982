<div class="flex justify-between content-center ">


<section class="flex-inline justify-between w-full p-5 pb-15" >

  <ng-container *ngIf="!component.selectedExpense" >
    <ng-component *ngTemplateOutlet="expenseFolderList"></ng-component>
  </ng-container>
  <ng-container *ngIf="component.selectedExpense" >
    <ng-component *ngTemplateOutlet="floaterDetail"></ng-component>
  </ng-container>
</section>

</div>

<!--

  Floater Detail Component Template
  Begins here

-->


<ng-template #floaterDetail>


    <app-view-floater></app-view-floater>

    <!-- Expense Check : Name -->
    <!-- FolderCheckId/GPX -->
    <!-- FolderCheckOrderNumber - FolderNumber-->

    <!--

      Unkonwns  :
      ? - greenStatus : 0
      ? - lookIntoFolder : 1/0
      ? - Janice : 0

    -->

</ng-template>
<!--

  Floater Detail Component Template
  Ends here

-->





<!--

  Expense Folder List Component Template
  Begins here

-->

<ng-template #expenseFolderList>
  <div class="mt-4 md:mt-2 text-3xl md:text-6xl font-extrabold tracking-tight leading-7 sm:leading-10">
    Expense Management
</div>
  <section class="w-full justify-end flex" >
       <!-- Title -->

    <div class="w-fit">
      <mat-icon (click)="openExpenseModal('new-folder')" class=" cursor-pointer " svgIcon="heroicons_outline:plus-circle"></mat-icon>
      <mat-icon (click)="upkeepTheList()" svgIcon="heroicons_solid:arrow-path" class="ml-3  cursor-pointer"  ></mat-icon>
      <mat-icon (click)="component.listActions = !component.listActions"  svgIcon="heroicons_outline:cog" class=" ml-3 cursor-pointer" ></mat-icon>
    </div>
  </section>
  <section class="w-full justify-between flex" >
    <app-list-floaters-expenses></app-list-floaters-expenses>
  </section>
</ng-template>

<!--

  Expense Folder List Component Template
  Ends here

-->

