import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { kodAnimations } from '@kod/animations';
import { KodAlertType } from '@kod/components/alert';
import { ExpenseLibService } from '../../lib/lib-expense';

@Component({
  selector: 'modal-expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  animations: kodAnimations
})

export class ExpenseModalComponent implements OnInit {

  expenseFormGroup: UntypedFormGroup;

  showAlert: boolean = false;
  alert: { type: KodAlertType; message: string } = {
    type: 'success',
    message: ''
  };

  // formSet is a prototype object that contains arbetray form fields
  // my hopes are that this can be used to create dynamic forms
  private _formSet = {
    new: {
      1: { name: 'folderName', purpose: 'Folder Name' },
      2: { name: 'description', purpose: 'Description/Purpose' },
      name: 'Create Folder',
    },
  }

  public _mydata: any;
  public dataReady: boolean = false;
  public buttonSubmit = false;

  get formSet() {
    return this._formSet;
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _dialogRef: MatDialogRef<ExpenseModalComponent>,
    private _expense: ExpenseLibService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {

    this._mydata = this.data;

    this.dataReady = true;
    this.expenseFormGroup = this._formBuilder.group({
      folderName: [this._mydata.editData.folderName || ''],
      description: [this._mydata.editData.description || ''],
    });
  }

  private closeModal(status?: object) {
    if (status) {
      this._dialogRef.close(status);
    } else {
      this._dialogRef.close();
    }
  }

  private handleError(error: string) {
    let errorLimit = 255;
    if (error.length > errorLimit) error = error.substring(0, errorLimit) + '...';
    this.showAlert = true;
    this.alert = {
      type: 'error',
      message: error
    };
  }

  async submit() {
    if (this.expenseFormGroup.invalid) {
      this.handleError('Please fill out all fields');
      return;
    }
    this.buttonSubmit = true;
    if (this._mydata.selection == 'new-folder') {
      await this._expense.createExpenseFloaterStrict(this.expenseFormGroup.value).then(result => {
        this.closeModal({ event: 'save', data: result });
      }).catch(err => {
        this.handleError(err.error);
      })
    }
    if(this._mydata.selection == 'update-folder'){
      await this._expense.updateExpenseFloaterByGPXStrict(this._mydata.editData.folderGPX, this.expenseFormGroup.value).then(result => {

       console.log("update result is  : ",result);
        this.closeModal({ event: 'update', data: result });
      }).catch(err => {
        this.handleError(err.error);
      })
    }

  }
}
