import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KodDrawerComponent } from '@kod/components/drawer/drawer.component';

@NgModule({
    declarations: [
        KodDrawerComponent
    ],
    imports     : [
        CommonModule
    ],
    exports     : [
        KodDrawerComponent
    ]
})
export class KodDrawerModule
{
}
