<div class="flex flex-wrap  w-full h-full mt-10">
  <ng-container *ngIf="floaters.length == 0">
    <h2>No Expenses Available ... Try creating one to start.</h2>
  </ng-container>

  <ng-container *ngIf="floaters.length != 0">
    <div class="flex" *ngFor="let folder of floaters">
      <div class="relative w-40 h-40 m-2 p-4 shadow rounded-2xl bg-card">

        <a class="z-10 absolute inset-0 flex flex-col p-4 cursor-pointer" (click)="selectFloat(folder)">
          <div class="aspect-[9/6]">
            <div class="flex items-center justify-center h-full">
              <!-- Icon -->
              <mat-icon class="icon-size-14 text-hint opacity-50" [svgIcon]="'heroicons_solid:folder'"> </mat-icon>
            </div>
          </div>
          <div class="flex flex-col flex-auto justify-center text-center text-sm font-medium">
            <div class="truncate" [matTooltip]="folder.description">{{folder.folderName}}</div>
            <ng-container *ngIf="folder.description">
              <div class="text-secondary truncate">Total {{folder.status.total | currency}}</div>
            </ng-container>
          </div>
        </a>
        <span class="absolute z-20 top-1.5 right-1.5 w-8 h-8 min-h-8 " *ngIf="component.listActions && folder.folderName !== 'System.Owned.Folder'" >
          <mat-icon class="icon-size-5 cursor-pointer" [color]="red" [svgIcon]="'heroicons_outline:pencil-square'"
            [matTooltip]="'Edit '+folder.folderName" (click)="openExpenseModal('update-folder',folder)"></mat-icon>

          <mat-icon class="icon-size-5 cursor-pointer" [color]="red" [svgIcon]="'mat_solid:delete'"
            [matTooltip]="'Delete '+folder.folderName" (click)="deleteFloat(folder)"></mat-icon>
        </span>

      </div>
    </div>
  </ng-container>
</div>
