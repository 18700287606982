import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { KodConfirmationModule } from '@kod/services/confirmation';
import { KodLoadingModule } from '@kod/services/loading';
import { KodMediaWatcherModule } from '@kod/services/media-watcher/media-watcher.module';
import { KodPlatformModule } from '@kod/services/platform/platform.module';
import { KodSplashScreenModule } from '@kod/services/splash-screen/splash-screen.module';
import { KodUtilsModule } from '@kod/services/utils/utils.module';

@NgModule({
    imports  : [
        KodConfirmationModule,
        KodLoadingModule,
        KodMediaWatcherModule,
        KodPlatformModule,
        KodSplashScreenModule,
        KodUtilsModule
    ],
    providers: [
        {
            // Disable 'theme' sanity check
            provide : MATERIAL_SANITY_CHECKS,
            useValue: {
                doctype: true,
                theme  : false,
                version: true
            }
        },
        {
            // Use the 'fill' appearance on Angular Material form fields by default
            provide : MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            }
        }
    ]
})
export class KodModule
{
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: KodModule)
    {
        if ( parentModule )
        {
            throw new Error('KodModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
