import { Component, OnInit } from '@angular/core';
import { KodBoxService } from 'ngx-kodbox';

@Component({
  selector: 'app-list-floaters-expenses',
  templateUrl: './list-floaters.component.html',
  styleUrls: ['./list-floaters.component.scss']
})
export class ListFloatersComponent implements OnInit {


  get component(){
    return this._wild.get('expense');
  }

  get floaters() {
    if( this.component && this.component.data ){
    return this.component.data.floaters;
    }else{
      return [];
    }
  }

  constructor(
    private _wild:KodBoxService
  ) {

  }


  ngOnInit(): void {
  }


  selectFloat(floater){
    this._wild.lambda('selectFloat')(floater);
  }

  openExpenseModal(str: string, data?: any) {
    this._wild.lambda('openExpenseModal')(str, data);
  }

  async deleteFloat(floater) {
    this._wild.lambda('deleteFloat')(floater);
  }

  docs():string{
    return `

    Floaters are folders..

    Image a folder that is just on a table. Its floating around in the room with no home.


    It has a bunch of reciepts inside that we later will use.


    Floaters have the ability to be in different levels
    .. example you can have a folder inside another folder

    this is referedd to as the level of the folder.

    level - allows the system to know how to display it.


    floaters / folders can have numbers as well...

    Imagine you have three folders of the same name on your desk..

    This can be a month 1, month 2, month 3 folder type stucture.

    `
  }

}
