import { Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { KodConfirmationConfig, KodConfirmationService } from '@kod/services/confirmation';

import { KodBoxService } from 'ngx-kodbox';
import { DateTime } from 'luxon';
import { ExpenseLibService } from '../../lib/lib-expense';

@Component({
  selector: 'app-view-floater',
  templateUrl: './view-floater.component.html',
  styleUrls: ['./view-floater.component.scss']
})
export class ViewFloaterComponent implements OnInit {

  addSingleExpense: UntypedFormGroup;
  editSingleExpense: UntypedFormGroup;

  formFieldHelpers: string[] = [''];
  folderUse: boolean = false;
  foldersSelect: FormControl = new FormControl();


  private skinConfirm: KodConfirmationConfig =
  {
    title: "Delete Expense ?",
    message: "Are you sure you want to delete this expense? <br /><br /><span class=\"font-medium\">This action cannot be undone!</span>",
    icon: {
      show: true,
      name: "heroicons_outline:exclamation",
      color: "warn"
    },
    actions: {
      confirm: {
        show: true,
        label: "Delete",
        color: "warn"
      },
      cancel: {
        show: true,
        label: "Cancel"
      }
    },
    dismissible: true
  }



  get buttonStatus(): boolean {
    if (this.folderUse) {

      if (this.foldersSelect.invalid || this.foldersSelect.value == '' || this.foldersSelect.value == null) {
        return true;
      }
    } else {
      if (this.addSingleExpense.invalid) {
        return true;
      }
    }
    return false;
  }

  get component() {
    return this._wild.get('expense');
  }

  get floater() {
    return this.component.selectedExpenseData;
  }



  get totalExpenses() {

    let total = 0;
    this.component.list.forEach(expense => {
      total += expense.itemPrice * expense.itemQuantity || expense.status.total;
    });
    return "$" + total.toFixed(2);
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _wild: KodBoxService,
    private _expense: ExpenseLibService,
    private _kodConfirmationService: KodConfirmationService

  ) {

  }
  ngOnInit(): void {


    this.addSingleExpense = this._formBuilder.group({
      type: [],
      itemName: ['', [Validators.required]],
      itemPrice: ['', [Validators.required]],
    });

    this.editSingleExpense = this._formBuilder.group({
      itemName: ['', [Validators.required]],
      time:[],
      itemPrice: ['', [Validators.required]],
      itemQuantity: [''],
      numItems: [''],
      chargeType: [''],
      occurance: [''],
      place:[''],
      folder:[''],
      notes:[''],

    });

    this.init();
  }
  private async init() {
    this.component.data.ready = false;
    this.component.list = [];
    await this._expense.getAllExpensesViaGPXStrict(this.component.selectedExpenseData.folderGPX).then(async res => {
      console.log(res);
      if (this.floater.subFolders != null) {
        for (let i = 0; i < this.floater.subFolders.length; i++) {
          console.log(this.floater.subFolders[i]);
          await this._expense.getExpenseFloaterByGPXStrict(this.floater.subFolders[i]).then(res23 => {
            console.log(res23);
            this.component.list.push(res23);
          }).catch(err => {
            console.log(err);
          });
        }
      }
      this.component.list = this.component.list.concat(res);
      console.log(this.component.list);

    }).finally(() => {
      this.component.data.ready = true;
    }).catch(err => {
      console.log(err);
    });
  }
  expenseDetails() {
    return this.component.selectedExpenseData;
  }


  async deselectExpense() {
    this.component.selectedType = 'floater';
    // reassign the floater to the selectedExpenseData
    this.component.selectedExpenseData = this.component.data.all.find((listedFolders) => {
      return listedFolders.folderGPX == this.component.selectedExpenseData.folderIdNum;
    });
    await this.init();
  }
  deselectFloater() {
    this.component.selectedExpense = false;
    this.component.selectedExpenseData = null;
    this.component.selectedType = 'floater';
  }


  delete(gpx:string): void {
      // Open the dialog and save the reference of it
      const dialogRef = this._kodConfirmationService.open(this.skinConfirm);

      // Subscribe to afterClosed from the dialog reference
      dialogRef.afterClosed().subscribe((result) => {
          console.log(result);
          if(result == 'confirmed'){
            this.performDelete(gpx);
          }else{
            this.deselectExpense();
          }
      });
  }

  private performDelete(gpx:string){
    if(gpx == null)return;
    this._expense.deleteExpenseByGPXStrict(gpx).then((res)=>{
      var tmpGPX = this.component.list.filter((expense)=>{
        return expense.gpx == gpx;
      }).pop();
      console.log("tmpGPX",tmpGPX);

      // update both the floaters and the overall arrays
      this.component.data.floaters.find((floater)=>{
        if(floater.folderGPX == tmpGPX.folderIdNum){
          floater.status.total = floater.status.total - tmpGPX.itemTotal;
          floater.status.balance = parseFloat(floater.status.total.toFixed(2));
          return true;
        }
      });
      this.component.data.all.find((floater)=>{
        if(floater.folderGPX == tmpGPX.folderIdNum){
          floater.status.total = floater.status.total - tmpGPX.itemTotal;
          floater.status.balance = parseFloat(floater.status.total.toFixed(2));
          return true;
        }
      });

      // remove the expense from the list
      this.component.list = this.component.list.filter((expense)=>{
        return expense.gpx != gpx;
      })
      this.deselectExpense();
    }).catch((err)=>{
      console.log(err);
    })
  }
  private async addSingleExpenseToFloater() {
    // validate the form
    if (this.addSingleExpense.invalid || isNaN(parseFloat(this.addSingleExpense.value.itemPrice)))return;

    var readyUp = this.addSingleExpense.value;
    readyUp.itemPrice = parseFloat(readyUp.itemPrice);
    delete readyUp.type;
    readyUp['folder'] = this.floater.folderName;
    readyUp['folderIdNum'] = this.floater.folderGPX;
    readyUp['folderNumber'] = this.floater.folderNumber;

    await this._expense.createExpenseStrict(readyUp).then((res) => {
      console.log(res);
      this.addSingleExpense.reset();
      // add into list
      this.component.list = [ ...this.component.list.concat(res)];
      // update the arrays
      this.component.data.floaters.find((floater)=>{
        if(floater.folderGPX == res.folderIdNum){
          floater.status.total = floater.status.total + res.itemTotal;
          floater.status.balance = parseFloat(floater.status.total.toFixed(2));
          return true;
        }
      });
      this.component.data.all.find((floater)=>{
        if(floater.folderGPX == res.folderIdNum){
          floater.status.total = floater.status.total + res.itemTotal;
          floater.status.balance = parseFloat(floater.status.total.toFixed(2));
          return true;
        }
      });

    }).catch((err) => {
      console.log(err);
    })

  }
  cleanClocks(tmpNumber) {
    return DateTime.local().toFormat("EEEE',' MMMM d',' ha");
  }
  private async addFloaterToFloater() {
    // validate the form
    if (this.foldersSelect.invalid || this.foldersSelect.value == '' || this.foldersSelect.value == null) {
      return;
    }
    console.log("child", this.foldersSelect.value, " parent", this.floater.folderGPX);
    // print out object to console

    await this._expense.assignFolderToFolderStrict(this.foldersSelect.value, this.floater.folderGPX).then((res) => {
      console.log(res);
      this.foldersSelect.setValue('');

    }).catch((err) => {
      console.log(err);
    })



  }

  // Used when selecting a expense to be edited
  async setControlValues() {
    this.editSingleExpense.controls['itemName'].setValue(this.component.selectedExpenseData.itemName);
    this.editSingleExpense.controls['itemPrice'].setValue(this.component.selectedExpenseData.itemPrice);
    this.editSingleExpense.controls['time'].setValue(this.component.selectedExpenseData.time);
    this.editSingleExpense.controls['numItems'].setValue(this.component.selectedExpenseData.logics.numItems);
    this.editSingleExpense.controls['itemQuantity'].setValue(this.component.selectedExpenseData.itemQuantity);
    this.editSingleExpense.controls['folder'].setValue(this.component.selectedExpenseData.folderIdNum);
    this.editSingleExpense.controls['chargeType'].setValue(this.component.selectedExpenseData.logics.chargeType.type);
    this.editSingleExpense.controls['occurance'].setValue(this.component.selectedExpenseData.logics.chargeType.expires);
    this.editSingleExpense.controls['place'].setValue(this.component.selectedExpenseData.logics.place );
    this.editSingleExpense.controls['notes'].setValue(this.component.selectedExpenseData.logics.notes );
  }

  // The User clicks on a row :
  // is it a folder or a single expense?
  async readRow(row) {
    // check if object row has key 'folderGPX'
    if (row.hasOwnProperty('folderGPX')) {
      // if so, then it is a floater
      this.component.selectedExpenseData = row;
      this.component.selectedExpense = true;
      this.component.selectedType = 'floater';
      await this.init();
    } else {
      // if not, then it is an expense
      this.component.selectedExpenseData = row;
      this.component.selectedExpense = true;
      this.component.selectedType = 'expense';
      await this.setControlValues();
    }
  }
  add() {
    if (this.folderUse) {
      this.addFloaterToFloater();
    } else {
      this.addSingleExpenseToFloater();
    }
  }

  // Save edited expense
  async saveEditExpense()
  {
    if(this.editSingleExpense.invalid || isNaN(parseFloat(this.editSingleExpense.value.itemPrice)))return;
    if(this.editSingleExpense.pristine)this.deselectExpense();
    let tmp = this.editSingleExpense.value;
    if(tmp['chargeType'] == 'One Time Only')
    {
      tmp['occurance'] = "false";
    }
    console.log(tmp);
    await this._expense.updateSingleExpenseByGPXStrict(this.component.selectedExpenseData.gpx, tmp).then((res)=>{
      console.log(res);
      this.deselectExpense();
    });

  }

  docs(): string {
    return `

    View Floater ..

    Here you are viewing the expenses inside the floater/ aka folder.


    Here you can add expenses or move expenses to other folders.

    The two type of expenses you are able to add are:

    - single expense
    - an entire folder of expenses


    `
  }
}
