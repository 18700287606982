
<!-- INFORMATION SECTION -->

<section class="cursor-pointer w-full  " (click)="deselectFloater()">
  <div class="prose prose-sm max-w-3xl">
  <!-- Here we have folder information -->
    <h1>{{component.selectedExpenseData.folderName ? component.selectedExpenseData.folderName : component.selectedExpenseData.itemName}}</h1>
  <ng-container *ngIf="component.selectedExpenseData.folderName">
    <p>{{floater.description}}</p>
    <small>[folder] - {{component.list.length}} entries inside</small><br />
  <small>FN - {{floater.folderNumber}}</small>
  </ng-container>
  <hr />
</div>
</section>


<!-- DATA SECTION -->

<section class="w-full">
  <ng-container *ngIf="!component.data.ready">
    <mat-progress-bar mode="indeterminate" class="w-full" style="height:50vh;opacity:0.14" ></mat-progress-bar>
  </ng-container>
<!-- Only show our data section when our data is completely ready -->
<ng-container *ngIf="component.data.ready">
  <ng-containter *ngIf="component.selectedType == 'floater'">
    <!-- View inside the folder/floater itself -->
    <ng-component *ngTemplateOutlet="viewFloater"></ng-component>
  </ng-containter>
  <ng-containter *ngIf="component.selectedType == 'expense'">
    <!-- View inside the single expense -->
    <ng-component *ngTemplateOutlet="viewExpense"></ng-component>
  </ng-containter>
</ng-container>

</section>




<ng-template #viewFloater>
  <!-- Here we have actions -->
  <ng-component *ngTemplateOutlet="Actions"></ng-component>
  <!-- here we have enteries -->
  <ng-container *ngIf="component.list.length == 0">

    <div class="justify-center text-center mt-5 w-full">
      <small>Please create an expense or add in a folder to get started.</small>
    </div>

  </ng-container>

  <ng-container *ngIf="component.list.length > 0">

    <ng-component *ngTemplateOutlet="Entries"></ng-component>

  </ng-container>
</ng-template>




<ng-template #viewExpense>
  <!-- Sectioned form with dividers -->
  <div class="prose prose-sm max-w-3xl">
    <h2 class="mt-12">Expense : {{component.selectedExpenseData.itemName}}</h2>
    <p>
      Here we have all related information on this single expense.
    </p>
  </div>

  <form [formGroup]="editSingleExpense" class="flex flex-col mt-4 px-8 pt-10 bg-card shadow rounded overflow-hidden">
    <p class="text-lg font-medium">Basic information</p>
    <p class="text-secondary mb-6">
      Set name of the expense, amount that was charged and date here.
    </p>

    <!-- NAME && DATE -->
    <div class="flex">
      <!-- NAME -->
      <mat-form-field class="flex-auto  w-2/3">
        <input [formControlName]="'itemName'" matInput [placeholder]="'Expense Name'" required>
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:document-text'"></mat-icon>
      </mat-form-field>

      <!-- DATE OF EXPENSE -->
      <mat-form-field [ngClass]="formFieldHelpers" class="flex-auto w-1/3">
        <input matInput [formControlName]="'time'" [matDatepicker]="picker1">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>
    </div>


    <!-- PRICE/COST OF EXPENSE -->
    <div class="flex">
      <mat-form-field class="flex-auto">
        <input matInput type="number" [formControlName]="'itemPrice'" step=".01"
          [placeholder]="'Expense Price'" required>
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:currency-dollar'"></mat-icon>
      </mat-form-field>
    </div>

    <mat-divider class="mt-6 mb-10"></mat-divider>
    <p class="text-lg font-medium">Expense Details</p>
    <p class="text-secondary mb-6">
      Give details of the expense.
    </p>
    <!-- Qty and of Num Items -->
    <div class="flex">
      <!-- QTY -->
      <mat-form-field class="flex-auto">
        <mat-label>QTY</mat-label>
        <input matInput type="number" [formControlName]="'itemQuantity'" min=1
          [placeholder]="'Expense Qty'" required>
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
      </mat-form-field>
      <!-- # of Items -->
      <mat-form-field class="flex-auto">
        <mat-label>Number of Items</mat-label>
        <input matInput type="number" [formControlName]="'numItems'" min=1
          [placeholder]="'Expense Items'">
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:information-circle'"></mat-icon>
      </mat-form-field>
    </div>
    <!-- Single charge or reoccuring -->
    <div class="flex mb-4  mt-2 ">

      <div class="flex-auto w-1/2">
        <div class="flex flex-col gt-sm:mt-0 gt-sm:ml-16">
          <span class="font-semibold mb-2">Charge Type</span>
          <mat-radio-group formControlName="chargeType" class="flex flex-col" [color]="'primary'" >
            <mat-radio-button class="mb-2" [value]="'One Time Only'">
              One Time Only
            </mat-radio-button>
            <mat-radio-button class="mb-2" [value]="'Reoccuring'">
              Reoccuring
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <!-- If reoccuring choose time frame -->
      <div class="flex-auto w-1/2">
        <span id="expires" *ngIf="editSingleExpense.get('chargeType').value == 'Reoccuring'">
          <mat-form-field class="flex-auto mt-4 gt-xs:pl-3">
            <mat-select formControlName="occurance" [placeholder]="'Weekly'">
              <mat-option value=7>Weekly</mat-option>
              <mat-option value=14>Bi-Weekly</mat-option>
              <mat-option value=30>Monthly</mat-option>
              <mat-option value=360>Yearly</mat-option>
            </mat-select>
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'access_time'"></mat-icon>
          </mat-form-field>
        </span>
      </div>
    </div>
    <div class="flex flex-col gt-xs:flex-row">
      <mat-form-field class="flex-auto gt-xs:pr-3">
        <mat-select formControlName="place" [placeholder]="'Place Of Charge'">
          <mat-option value="Default">Default</mat-option>
          <mat-option value="Store">Store</mat-option>
          <mat-option value="Office">Office</mat-option>
          <mat-option value="Online">Online</mat-option>
          <mat-option value="Services Rendered<">Services Rendered</mat-option>
          <mat-option value="Other">Other</mat-option>
        </mat-select>
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:location-marker'">
          {{component.selectedExpenseData.logics.notes}}</mat-icon>
      </mat-form-field>
    </div>
    <mat-divider class="mt-6 mb-5"></mat-divider>
    <p class="text-lg font-medium">Expense Notes</p>
    <p class="text-secondary mb-6">
      Give notes on expense to help you remember why it was so necessary.
    </p>
    <div class="flex">
      <mat-form-field class="kod-mat-textarea flex-auto">
        <textarea matInput [placeholder]="'Notes'" formControlName="notes" [rows]="3"></textarea>
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:menu-alt-2'"></mat-icon>
      </mat-form-field>
    </div>
        <!--  Photo is still needed -->

    <!-- Give Info on what folder it is in -->
    <!-- Next you need delete / Save / Cancel -->

    <mat-divider class="mt-6 mb-10"></mat-divider>
    <div class="flex">
        <mat-form-field class="flex-auto">
          <mat-label>Expense Resides Inside :</mat-label>
          <mat-select formControlName="folder" [placeholder]="'Weekly'" >
            <mat-option *ngFor="let listedFolders of component.data.all" [value]="listedFolders.folderGPX"  >{{listedFolders.folderName}}</mat-option>
          </mat-select>
          <mat-icon class="icon-size-5" matPrefix [svgIcon]="'folder_open'"></mat-icon>
        </mat-form-field>
    </div>


    <div class="flex items-center justify-end border-t -mx-8 mt-8 px-8 py-5 bg-gray-50 dark:bg-gray-700">
      <button (click)="saveEditExpense()" class="px-6 ml-3" mat-flat-button [color]="'primary'">
        Save
      </button>
      <button mat-button (click)="deselectExpense()" class="px-6 ml-3">
        Cancel
      </button>
      <button class="px-6 ml-3" (click)="delete(component.selectedExpenseData.gpx)"  mat-flat-button [color]="'warn'">
        Delete
      </button>
    </div>
  </form>

</ng-template>








<ng-template #Actions>
  <mat-table [dataSource]="['empty']" class="w-full">
    <!-- Selection Type Column -->
    <ng-container matColumnDef="type">
      <mat-cell *matCellDef="let element">
        <mat-slide-toggle (change)="folderUse = !folderUse" [checked]="folderUse">
        </mat-slide-toggle>
      </mat-cell>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="selection">
      <mat-cell *matCellDef="let element">

        <ng-container *ngIf="folderUse">
          <div class="flex w-full  ">
            <mat-form-field [ngClass]="formFieldHelpers" class="flex-auto">
              <mat-label>Add In Folder</mat-label>
              <mat-select [formControl]="foldersSelect">
                <ng-container *ngFor="let folder of component.data.all">
                  <!-- We only add into list folders that is not the current one.-->
                  <mat-option *ngIf="folder.folderGPX != floater.folderGPX && folder.belongsTo != floater.folderGPX " value="{{folder.folderGPX}}">
                    {{folder.folderName}}</mat-option>
                </ng-container>
              </mat-select>
              <mat-icon class="icon-size-5" matPrefix [svgIcon]="'folder_open'"></mat-icon>
              <mat-hint>Add in entire expense folder into this folder.</mat-hint>
            </mat-form-field>
          </div>
        </ng-container>
        <!-- Add single expense -->
        <ng-container *ngIf="!folderUse">
          <div class="inline-flex w-full justify-center gap-1 " [formGroup]="addSingleExpense">
            <div class="flex w-2/3">
              <mat-form-field [ngClass]="formFieldHelpers" class="flex-auto">
                <mat-label>Expense</mat-label>
                <input [formControlName]="'itemName'" matInput name="expenseName" [placeholder]="'Expense'">
                <mat-hint>Name of the Expense</mat-hint>
              </mat-form-field>
            </div>
            <div class="flex ">
              <mat-form-field [ngClass]="formFieldHelpers" class="flex-auto">
                <mat-label>Amount</mat-label>
                <input [formControlName]="'itemPrice'" type="number" name="expenseAmount" [placeholder]="'0.00'"
                  matInput>
                <span matPrefix>$</span>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </mat-cell>
    </ng-container>
    <!-- Add Column -->

    <ng-container matColumnDef="action">
      <mat-cell *matCellDef="let element">

        <div class="w-1/3">
          <button [disabled]="buttonStatus" class="flex" mat-icon-button aria-label="add row" (click)="add()">
            <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
          </button>
        </div>

      </mat-cell>
    </ng-container>

    <mat-row *matRowDef="let row; columns: ['type', 'selection', 'action' ];"></mat-row>
  </mat-table>

</ng-template>



















<ng-template #Entries>
  <section class="w-full">

    <mat-table [dataSource]="component.list" class="w-full">
      <!-- Index Column -->
      <ng-container matColumnDef="index">
        <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
        <mat-cell *matCellDef="let element;let i = index"> {{i+1}} </mat-cell>
      </ng-container>

      <!-- Time/Date Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{cleanClocks(element.time || element.timestamp)}} </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.itemName || element.folderName}} </mat-cell>
      </ng-container>

      <!-- Price Column -->
      <ng-container matColumnDef="total">
        <mat-header-cell *matHeaderCellDef> Price </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.itemPrice || element.status.total | currency }} </mat-cell>
      </ng-container>

      <!-- Grand Total/ Actions Column -->
      <ng-container matColumnDef="disclaimer">
        <mat-cell mat-footer-cell *matFooterCellDef colspan="4">
          <div class="text-end w-full">
            | Total Expenses - {{totalExpenses}} |
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="['index', 'date', 'name', 'total' ]"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['index', 'date', 'name', 'total' ];" (click)="readRow(row)"></mat-row>
      <mat-footer-row *matFooterRowDef="['disclaimer']"></mat-footer-row>
    </mat-table>

  </section>
</ng-template>
