<section class="flex-inline justify-between w-full p-5 pb-15" >

  <div class="flex justify-between content-center ">
    <div class="item w-fit">
      <h1>Your Todo List Has {{todoList.data.length}} Tasks</h1>
    </div>

    <div class="item w-32">
      <button class="btn btn-primary" (click)="openTodo('new')">Add Todo</button>
    </div>
  </div>

  <hr>

<ng-container *ngIf="todoList.data.length > 0">
  <mat-table [dataSource]="todoList.data" multiTemplateDataRows class="mat-elevation-z8">
  <ng-container matColumnDef="{{column}}" *ngFor="let column of todoList.columns">
    <mat-header-cell *matHeaderCellDef>
      {{column == 'name' ? '' : ''}}
      {{column == 'task' ? '' : ''}}
      {{column == 'dateDue' ? '' : ''}}
      {{column == 'actions' ? 'Actions' : ''}}
    </mat-header-cell>
    <mat-cell *matCellDef="let parentElement" class="pl-6 md:pl-8 lg:pl-12 {{parentElement.completed ? 'completed' : ''}} " >
      <div *ngIf="column == 'name'" class="min-w-15">
        <small>{{parentElement[column]}}</small>
      </div>
      <div *ngIf="column == 'task'" class="min-w-40" [matTooltip]="parentElement.completed ? parentElement['name']+' - '+parentElement['task'] : ''" >
        <small>{{parentElement[column]}}</small>
      </div>
      <div *ngIf="column == 'actions'" class="min-w-40 text-right">
        <div class="ml-auto inline-flex">
          <div class="text-sm content-center" >
            <button class="mt-3" *ngIf="!parentElement.completed" mat-icon-button>
                  <mat-icon svgIcon="edit" class="icon-size-5" (click)="openTodo('edit', parentElement)"></mat-icon>
            </button>
          </div>
          <div class="text-sm content-center" >
            <button class="mt-3" (click)="delete('todo',parentElement['todoid'])" mat-icon-button>
              <mat-icon class="icon-size-5"
              [svgIcon]="'heroicons_solid:trash'">
              </mat-icon>
            </button>
          </div>
          <div class="text-sm content-center" >
            <button class="ml-2 mt-3">
              <mat-checkbox [checked]="parentElement.completed"  (click)="taskChanged(parentElement)" ></mat-checkbox>
            </button>
          </div>
      </div>
      </div>
      <!--Actions-->

    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="expand">
    <mat-header-cell *matHeaderCellDef aria-label="row actions"   >&nbsp;</mat-header-cell>
    <mat-cell *matCellDef="let parentElement" class="pl-6 md:pl-8 lg:pl-12 {{parentElement.completed ? 'completed' : ''}} ">
      <ng-container *ngIf="parentElement.subtasks.data.length >0 && !parentElement.completed">
        <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === parentElement ? null : parentElement); $event.stopPropagation()">
          <mat-icon *ngIf="expandedElement !== parentElement">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === parentElement">keyboard_arrow_up</mat-icon>
        </button>
      </ng-container>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="add">
    <mat-header-cell *matHeaderCellDef aria-label="row actions" >&nbsp;</mat-header-cell>
    <mat-cell *matCellDef="let parentElement" class="pl-6 md:pl-8 lg:pl-12 {{parentElement.completed ? 'completed' : ''}} ">
      <button mat-icon-button aria-label="add row" (click)="openTodo('subtask', parentElement)" >
        <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
      </button>
    </mat-cell>
  </ng-container>
  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <mat-cell *matCellDef="let parentElement" [attr.colspan]="todoList.columnsExpand.length">
      <div style="width:100%;" class=" pl-5 pr-5 example-element-detail {{parentElement == expandedElement ? 'expanded' : 'collapsed'}}" [@detailExpand]="parentElement == expandedElement ? 'expanded' : 'collapsed'">

        <!-- Begin Mini Task -->
          <ng-container  *ngIf="parentElement.subtasks.data.length > 0">
            <mat-table [dataSource]="parentElement.subtasks.data" class="mat-elevation-z0 h-fit pb-3 relative overflow-x-visible w-full">
          <ng-container *ngFor="let colName of parentElement.subtasks.columns; let isLastCol = last;let isFirstcol = first">

              <ng-container [matColumnDef]="colName">
                <mat-header-cell *matHeaderCellDef style="height: 0px;" class="noshow">
                </mat-header-cell>
                  <!-- Rows -->
                  <mat-cell *matCellDef="let element; let isFirstRow = first; let isLastRow = last"
                      class="pl-6 md:pl-8 lg:pl-12 {{element.completed ? 'completed' : ''}} " >

                      <div *ngIf="colName == 'name'" class="min-w-15">
                        <small>{{element[colName]}}</small>
                      </div>
                      <div *ngIf="colName == 'task'" class="min-w-40" [matTooltip]="element.completed ? element['name']+' - '+element['task'] : ''" >
                        <small>{{element[colName]}}</small>
                      </div>
                      <div *ngIf="colName == 'actions'" class="min-w-40 text-right">
                        <div class="ml-auto inline-flex">
                          <div class="text-sm content-center" >
                            <button class="mt-3"  *ngIf="!element.completed" mat-icon-button>
                                  <mat-icon svgIcon="edit" class="icon-size-5" (click)="openTodo('subtask-edit', element)"></mat-icon>
                            </button>
                          </div>
                          <div class="text-sm content-center" >
                            <button class="mt-3"  (click)="delete('subtask',element['id'], parentElement)" mat-icon-button>
                              <mat-icon class="icon-size-5"
                              [svgIcon]="'heroicons_solid:trash'">
                              </mat-icon>
                            </button>
                          </div>
                          <div class="text-sm content-center" >
                            <button class="ml-2 mt-3">
                              <mat-checkbox [checked]="element.completed"  (click)="taskChanged(element)" ></mat-checkbox>
                            </button>
                          </div>
                      </div>
                      </div>
                      <!--Actions-->
                  </mat-cell>1
              </ng-container>
          </ng-container>
          <mat-header-row class="noshow" *matHeaderRowDef="parentElement.subtasks.columns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: parentElement.subtasks.columns; "></mat-row>
        </mat-table>
`      </ng-container>
        <!-- end Mini Tasks -->

        </div>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="todoList.columnsExpand"></mat-header-row>
  <mat-row *matRowDef="let element; columns: todoList.columnsExpand;"
      class="example-element-row
      {{element.delete ? 'flash-red': ''}}
      {{element.edit ? 'flash-yellow': ''}}
      {{element.new ? 'flash-green': ''}} "
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </mat-row>
  <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
</mat-table>
</ng-container>

</section>
