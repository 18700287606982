import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { KodAlertModule } from '@kod/components/alert';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserModalComponent} from '@app/shared/modals/user/user.component';


@NgModule({
  declarations: [
    UserModalComponent,
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    KodAlertModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
    ],
  exports: [

  ],
})
export class ModalsControllerModule { }


/*
    MatTabsModule,
    MatStepperModule,
*/
