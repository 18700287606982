import { IconsMockApi } from '@app/mock-api/ui/icons/api';
import { MessagesMockApi } from '@app/mock-api/common/messages/api';
import { NavigationMockApi } from '@app/mock-api/common/navigation/api';
import { NotificationsMockApi } from '@app/mock-api/common/notifications/api';
import { ShortcutsMockApi } from '@app/mock-api/common/shortcuts/api';



export const mockApiServices = [
    IconsMockApi,
    MessagesMockApi,
    NavigationMockApi,
    NotificationsMockApi,
    ShortcutsMockApi,
];
