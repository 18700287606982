import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AppHttpClient } from '@lib/http/app-http-client.service';
import { KodBoxService } from 'ngx-kodbox';
import { firstValueFrom, map, Observable } from 'rxjs';
import { authInterfaceHelper as a } from './lib-auth-interface-helper';

/**
 * Authentication Library Prototype
 *  - This is meant to unify the authentication/security process of the application
 *
 * (Prototype Library API)
 */

@Injectable({
    providedIn: 'root'
})
export class AuthLibService {

    private libraryEnabled: boolean = true;



    constructor(
      private _http: HttpClient,
      @Inject('BASE_URL') baseUrl: string,
      private _wild:KodBoxService,
      private http: AppHttpClient,
      ) {

        this._wild.setLambda("HTTP", _http);
        this._wild.set("baseUrl", baseUrl);
        // first thing check if the library is allowed
        if (!isDevMode()) {
            const checkLibStatus = async () => { this.libraryEnabled = await this.libEnabled(); };
            checkLibStatus();
        }
        if (isDevMode()) {
          console.log('[ Auth library Service is ' + (this.libraryEnabled ? 'enabled' : 'disabled') + ' ]');
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Auth Library Global Controller | Begins
    // -----------------------------------------------------------------------------------------------------


    // Ask the server if box library should be enabled | Begin
    private requestIfAuthLibraryIsEnabled(): Observable<boolean> {
        return new Observable(observer => { true });  // Later down the line, this will be replaced with a call to the API
    }
    private async libEnabled(): Promise<boolean> {
        return await firstValueFrom(this.requestIfAuthLibraryIsEnabled());
    }
    //
    //


    // Call the API to logout a user
    private callApiForVersion(): Observable<any> {
        return this.http.get('/api/kod/info');
    }
    //
    public async Version(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForVersion()).catch(err => {
              console.log(err)
            });
            // console.log(response);
            this._wild.set("version", response);
            return resolve(response);
        });
    }

    // Setup Roles for the site to use
    private callApiGenerateRols(): Observable<any> {
      return this.http.get('/api/account/generate/roles');
    }
    //
    public async GenerateRoles(): Promise<any> {
      return new Promise(async (resolve, reject) => {
        let response = await firstValueFrom(this.callApiGenerateRols()).catch(err => {
          console.log(err)
        });
        return resolve(response);
      });
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Auth Library Global Controller | Ends
    // -----------------------------------------------------------------------------------------------------


    //
    //


    // -----------------------------------------------------------------------------------------------------
    // @ Auth Library Data Information | Begins
    // -----------------------------------------------------------------------------------------------------

    public giveTokenPayLoad(token:any): any {
        const jwtHelper = new JwtHelperService();
        this._wild.set("tokenData", jwtHelper.decodeToken<a['AR']>(token));
        return jwtHelper.decodeToken<a['AR']>(token);
    }


    // Call the API to get the Two Factor Model
    private callApiForGetTwoFactorModel(): Observable<a['TFM']> {
        return this.http.Get('/users/GetTwoFactorModel');
    }
    public async getTwoFactorModel(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let model = await firstValueFrom(this.callApiForGetTwoFactorModel()).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Get Two Factor Model Error");
                return reject(err.error);
            });
            return resolve(model);
        });
    }
    /**
     * Function will get Two Factor Model
    */
    public async getTwoFactorModelStrict(): Promise<a['TFM']> {
        return await this.getTwoFactorModel();
    }
    //
    //

    // Call the API, see if email exists
    private callApiForEmailExists(email:string): Observable<boolean> {
        return this.http.Get('/users/EmailExists', email, 'email');
    }
    public async emailExists(email: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let exists = await firstValueFrom(this.callApiForEmailExists(email)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Email Exists Error");
                return reject(err.error);
            });
            return resolve(exists);
        });
    }
    /**
     * Function will check to see if email exists
     */
    public async emailExistsStrict(email: string): Promise<boolean> {
        return await this.emailExists(email);
    }
    //
    //



    // -----------------------------------------------------------------------------------------------------
    // @ Auth Library Data Information | Ends
    // -----------------------------------------------------------------------------------------------------

    //
    //

    // -----------------------------------------------------------------------------------------------------
    // @ Auth Library Data Actions | Begins
    // -----------------------------------------------------------------------------------------------------








    // Call the API to register a new user
    private callApiForRegister(user: a['RP']): Observable<any> {
        return this.http.Post('/api/account/create/user', user);
    }
    public async register(params: a['RP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let result = await firstValueFrom(this.callApiForRegister(params)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Register Error");
                return reject(err);
            });
            return resolve(result);
        });
    }
    /**
     * Function will register a new user
     */
    public async registerStrict(params: a['RP']): Promise<any> {
        return await this.register(params);
    }
    //
    //


    // Call the API to reset password
    private callApiForResetPassword(params:a['RPP']): Observable<any> {
        return this.http.Put('/users/ResetPassword', params);
    }
    public async resetPassword(params: a['RPP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForResetPassword(params)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Reset Password Error");
                return reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will reset password
     */
    public async resetPasswordStrict(params: a['RPP']): Promise<any> {
        return await this.resetPassword(params);
    }
    //
    //



    // Call the API to send reset password email
    private callApiForSendResetPasswordEmail(): Observable<any> {
        return this.http.Post('/users/SendResetPasswordEmail');
    }
    public async sendResetPasswordEmail(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForSendResetPasswordEmail()).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Send Reset Password Email Error");
                return reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will send reset password email
     */
    public async sendResetPasswordEmailStrict(): Promise<any> {
        return await this.sendResetPasswordEmail();
    }
    //
    //


    // Call the API to send email confirmation
    private callApiForSendEmailConfirmation(params: a['ECP']): Observable<any> {
        return this.http.Post('/users/SendEmailConfirmation', params);
    }
    public async sendEmailConfirmation(params: a['ECP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForSendEmailConfirmation(params)).catch(err => {
                if(isDevMode())console.log("[ Auth library Service ] - Send Email Confirmation Error");
                return  reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will send email confirmation
     */
    public async sendEmailConfirmationStrict(params: a['ECP']): Promise<any> {
        return await this.sendEmailConfirmation(params);
    }
    //
    //


    // Call the API to confirm email
    private callApiForConfirmEmail(params: a['VECP']): Observable<a['ECR']> {
        return this.http.Put<a['ECR']>('/users/ConfirmEmail', params);
    }
    public async confirmEmail(params: a['VECP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForConfirmEmail(params)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Confirm Email Error");
                return  reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will confirm email
     */
    public async confirmEmailStrict(params: a['VECP']): Promise<a['ECR']> {
        return await this.confirmEmail(params);
    }
    //
    //




    // Call the API to reset inital phone number
    private callApiForResetInitalPhoneNumber(params: a['RIPP']): Observable<any> {
        return this.http.Put('/users/ConfirmResetInitalPhoneNumber', params);
    }
    public async resetInitalPhoneNumber(params: a['RIPP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForResetInitalPhoneNumber(params)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Reset Inital Phone Number Error");
                return reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will reset inital phone number
     */
    public async resetInitalPhoneNumberStrict(params: a['RIPP']): Promise<any> {
        return await this.resetInitalPhoneNumber(params);
    }
    //
    //


    // Call the API to set phone password
    // Developer Note : For children of subscribers after confirmation email
    private callApiForSetPhonePassword(params: a['SPPP']): Observable<any> {
        return this.http.Put<any>('/users/SetPhonePassword', params);
    }
    public async setPhonePassword(params: a['SPPP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForSetPhonePassword(params)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Set Phone Password Error");
                return reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will set phone password
     */
    public async setPhonePasswordStrict(params: a['SPPP']): Promise<any> {
        return await this.setPhonePassword(params);
    }



    // Call the API to send two factor authentication code
    private callApiForSendTwoFactorAuthenticationCode(params: a['STFP']): Observable<any> {
        return this.http.Post('/users/SendTwoFactorAuthenticationCode', params);
    }
    public async sendTwoFactorAuthenticationCode(params: a['STFP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForSendTwoFactorAuthenticationCode(params)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Send Two Factor Authentication Code Error");
                return reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will send two factor authentication code
     */
    public async sendTwoFactorAuthenticationCodeStrict(params: a['STFP']): Promise<any> {
        return await this.sendTwoFactorAuthenticationCode(params);
    }
    //
    //



    // Call the API to verify two factor authentication code
    private callApiForVerifyTwoFactorAuthenticationCode(params: a['VTFP']): Observable<HttpEvent<a['VTFAR']>> {
        return this.http.PostWithOption<a['VTFAR']>('/users/VerifyTwoFactorAuthenticationCode', params, { withCredentials: true });
    }
    public async verifyTwoFactorAuthenticationCode(params: a['VTFP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForVerifyTwoFactorAuthenticationCode(params)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Verify Two Factor Authentication Code Error");
                return reject(err);
            });
            return resolve(response);
        });
    }
    /**
     * Function will verify two factor authentication code
     */
    public async verifyTwoFactorAuthenticationCodeStrict(params: a['VTFP']): Promise<a['VTFAR']> {
        return await this.verifyTwoFactorAuthenticationCode(params);
    }
    //
    //



    // Call the API to send reset phone number email
    private callApiForSendResetPhoneNumberEmail(): Observable<any> {
        return this.http.Post('/users/SendResetPhoneNumberEmail');
    }
    public async sendResetPhoneNumberEmail(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForSendResetPhoneNumberEmail()).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Send Reset Phone Number Email Error");
                return reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will send reset phone number email
     */
    public async sendResetPhoneNumberEmailStrict(): Promise<any> {
        return await this.sendResetPhoneNumberEmail();
    }
    //
    //


    // Call the API to reset phone number
    private callApiForResetPhoneNumber(params: a['RPhP']): Observable<void> {
        return this.http.Put('/users/ResetPhoneNumber', params);
    }
    public async resetPhoneNumber(params: a['RPhP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForResetPhoneNumber(params)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Reset Phone Number Error");
                return reject(err.error);
            });
            return resolve(response); // Dev Note : We also need to check the response to see if it was successful
        });
    }
    /**
     * Function will reset phone number
     */
    public async resetPhoneNumberStrict(params: a['RPhP']): Promise<any> {
        return await this.resetPhoneNumber(params);
    }
    //
    //

    // Call the API to reset forgotton password
    private callApiForResetForgottonPassword(params: a['RFPP']): Observable<any> {
        return this.http.Put('/users/ResetForgottonPassword', params);
    }
    public async resetForgottonPassword(params: a['RFPP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForResetForgottonPassword(params)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Reset Forgotton Password Error");
                return reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will reset forgotton password
     */
    public async resetForgottonPasswordStrict(params: a['RFPP']): Promise<any> {
        return await this.resetForgottonPassword(params);
    }
    //
    //


    // Call the API to send forgot password email
    private callApiForSendForgetPasswordEmail(params: a['ECP']): Observable<any> {
        return this.http.Post('/api/account/user/forgot-password', params);
    }
    public async sendForgetPasswordEmail(params: a['ECP']): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForSendForgetPasswordEmail(params)).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Send Forget Password Email Error");
                return reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will send forget password email
     */
    public async sendForgetPasswordEmailStrict(params: a['ECP']): Promise<any> {
        return await this.sendForgetPasswordEmail(params);
    }
    //
    //




    // Call the API to logout a user
    private callApiForLogout(): Observable<any> {
      return this.http.PostWithOption('/api/account/user/logout', null, { withCredentials: true });
  }
  public async logout(): Promise<any> {
      return new Promise(async (resolve, reject) => {
          let response = await firstValueFrom(this.callApiForLogout()).catch(err => {
              if (isDevMode())console.log("[ Auth library Service ] - Logout Error");
              return reject(err);
          });
          return resolve(response);
      });
  }
  /**
   * Function will logout the user
   */
  public async logoutStrict(): Promise<Observable<any>> {
      return await this.logout();
  }
  //
  //


  // Call the API to login a user
  private callApiForLogin(params: a['FG']): Observable<any> {
      return this.http.PostWithOption<any>('/api/account/user/login', params, { withCredentials: true });
  }
  public async login(params: a['FG']): Promise<any> {
      return new Promise(async (resolve, reject) => {
          let result = await firstValueFrom(this.callApiForLogin(params)).catch(err => {
              if (isDevMode())console.log("[ Auth library Service ] - Login Error");
              return reject(err.error);
          });
          return resolve(result);
      });
  }
  /**
   * Function will login the user
   */
  public async loginStrict(params: a['FG']): Promise<Observable<any>> {
      return await this.login(params);
  }
  //
  //

  // -----------------------------------------------------------------------------------------------------
  // @ Auth Library Data Actions | Ends
  // -----------------------------------------------------------------------------------------------------

  //
  //

  // -----------------------------------------------------------------------------------------------------
  // @ Auth Library Token Specific | Begins
  // -----------------------------------------------------------------------------------------------------





    // TODO : Replace the original with new function structure
    // Call the API to get token
    private callApiForGetAccessToken(): Observable<any> {
        return this.http.PostWithOption('/api/account/token', null, { withCredentials: true });
    }
    public async getAccessToken(): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let response = await firstValueFrom(this.callApiForGetAccessToken()).catch(err => {
                if (isDevMode())console.log("[ Auth library Service ] - Get Access Token Error");
                return reject(err.error);
            });
            return resolve(response);
        });
    }
    /**
     * Function will get access token
     */
    public async getAccessTokenStrict(): Promise<Observable<any>> {
        return await this.getAccessToken();
    }
    //
    //

    // -----------------------------------------------------------------------------------------------------
    // @ Auth Library Token Specific | Ends
    // -----------------------------------------------------------------------------------------------------

}
