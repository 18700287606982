import { NgModule } from '@angular/core';
import { KodUtilsService } from '@kod/services/utils/utils.service';

@NgModule({
    providers: [
        KodUtilsService
    ]
})
export class KodUtilsModule
{
    /**
     * Constructor
     */
    constructor(private _kodUtilsService: KodUtilsService)
    {
    }
}
