import { Inject, Injectable, isDevMode } from '@angular/core';
import { firstValueFrom, from, map, Observable } from 'rxjs';
import _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { KodBoxService } from 'ngx-kodbox';

/**
 * Todo Library Prototype
 *  - This is meant to unify the Todo process of the application
 *
 * (Prototype Library API)
 */
@Injectable({
    providedIn: 'root'
})
export class TodoLibService {

    private libraryEnabled: boolean = true;

    constructor(
      private http: HttpClient,
      @Inject('BASE_URL') baseUrl: string,
      private _wild:KodBoxService,
      ) {

        this._wild.setLambda("HTTP", http);
        this._wild.set("baseUrl", baseUrl);
        // first thing check if the library is allowed
        if (!isDevMode()) {
            const checkLibStatus = async () => { this.libraryEnabled = await this.libEnabled(); };
            checkLibStatus();
        }
        if (isDevMode()) {
            console.log('[ Todo library Service is ' + (this.libraryEnabled ? 'enabled' : 'disabled') + ' ]');
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Todo Library Global Controller | Begins
    // -----------------------------------------------------------------------------------------------------


    // Ask the server if Todo library should be enabled | Begin
    private requestIfUserLibraryIsEnabled(): Observable<boolean> {
        return new Observable(observer => { true });  // Later down the line, this will be replaced with a call to the API
    }
    private async libEnabled(): Promise<boolean> {
        return await firstValueFrom(this.requestIfUserLibraryIsEnabled());
    }
    //
    //


    // -----------------------------------------------------------------------------------------------------
    // @ Todo Library Global Controller | Ends
    // -----------------------------------------------------------------------------------------------------


    //
    //

    public sayHello(){
        if (this.libraryEnabled) {
            console.log('Hello from the Todo library');
        }
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Todo Library Data Information | Begins
    // -----------------------------------------------------------------------------------------------------

    //
    private callApiForGetToDoList(migration:boolean = false): Observable<any> {
      if(migration){
        return this._wild.lambda("HTTP").get('api/todo/update/migration');
      }else{
        return this._wild.lambda("HTTP").get('api/todo');
      }
    }
    public async getToDoList(migration:boolean = false): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const call = await firstValueFrom(this.callApiForGetToDoList(migration)).catch(err => {
                if (isDevMode())console.log("[ Todo library Service ] - Get ToDo List Error");
                if(isDevMode())console.log(err);
                return reject(err);
            });
            return resolve(call);
        });
    }
    /**
     * Function will get the list of todos from the system
     */
    public async getToDoListStrict(migration:boolean = false): Promise<any> {
        return await this.getToDoList(migration);
    }



    //
    private callApiForPatchUpdateTodo(id:string, data:any, subtask:boolean = false): Observable<any> {
      if(subtask){
        return this._wild.lambda("HTTP").patch('api/todo/subtasks/'+id, data);
      }else{
        return this._wild.lambda("HTTP").patch('api/todo/'+id, data);
      }
    }
    public async patchUpdateTodo(id:string, data:any, subtask:boolean = false): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const call = await firstValueFrom(this.callApiForPatchUpdateTodo(id, data, subtask)).catch(err => {
                if (isDevMode())console.log("[ Todo library Service ] - Update Todo Error");
                if(isDevMode())console.log(err);
                return reject(err);
            });
            return resolve(call);
        });
    }
    /**
     * Function will update the todo in the system
     */
    public async patchUpdateTodoStrict(id:string, data:any, subtask:boolean = false): Promise<any> {
        return await this.patchUpdateTodo(id, data, subtask);
    }
    //
    //




    //
    private callApiForCreateTodo(data:any,id?:string): Observable<any> {
        if(id){
          // this is a subtask
          return this._wild.lambda("HTTP").post('api/todo/subtasks/add/'+id, data);
        }else{
          // this is a todo
          return this._wild.lambda("HTTP").post('api/todo/add', data);
        }
    }
    public async createTodo(data:any,id?:string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const call = await firstValueFrom(this.callApiForCreateTodo(data,id)).catch(err => {
                if (id == null && isDevMode())console.log("[ Todo library Service ] - Create Todo Error");
                if (id && isDevMode())console.log("[ Todo library Service ] - Create Subtask Error");

                if(isDevMode())console.log(err);
                return reject(err);
            });
            return resolve(call);
        });
    }
    /**
     * Function will create a todo in the system
     */
    public async createTodoStrict(data:any,id?:string): Promise<any> {
        return await this.createTodo(data,id);
    }
    //
    //


    //
    private callApiForDeleteTodo(id:string,subtask:boolean=false): Observable<any> {
      if(subtask){
        return this._wild.lambda("HTTP").delete('api/todo/subtasks/delete/'+id);
      }else{
        return this._wild.lambda("HTTP").delete('api/todo/delete/'+id);
      }
    }
    public async deleteTodo(id:string,subtask:boolean=false): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const call = await firstValueFrom(this.callApiForDeleteTodo(id,subtask)).catch(err => {
                if (!subtask && isDevMode())console.log("[ Todo library Service ] - Delete Todo Error");
                if (subtask && isDevMode())console.log("[ Todo library Service ] - Delete Subtask Error");
                if(isDevMode())console.log(err);
                return reject(err);
            });
            return resolve(call);
        });
    }
    /**
     * Function will delete a todo in the system
     */
    public async deleteTodoStrict(id:string,subtask:boolean=false): Promise<any> {
        return await this.deleteTodo(id,subtask);
    }
    //
    //


    //
    private callApiForUpdateTodo(id:string, data:any, subtask:boolean =false): Observable<any> {
      if(subtask){
        return this._wild.lambda("HTTP").put('api/todo/subtasks/update/'+id, data);
      }else{
        return this._wild.lambda("HTTP").put('api/todo/update/'+id, data);
      }
    }
    public async updateTodo(id:string, data:any, subtask:boolean =false): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const call = await firstValueFrom(this.callApiForUpdateTodo(id, data, subtask)).catch(err => {
                if (isDevMode())console.log("[ Todo library Service ] - Put Update Todo Error");
                if(isDevMode())console.log(err);
                return reject(err);
            });
            return resolve(call);
        });
    }
    /**
     * Function will put update a todo in the system
     */
    public async updateTodoStrict(id:string, data:any, subtask:boolean =false): Promise<any> {
        return await this.updateTodo(id, data, subtask);
    }
    //
    //




    // -----------------------------------------------------------------------------------------------------


}

