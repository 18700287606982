<ng-container *ngIf="dataReady && _mydata.selection == 'new-folder'">
  <ng-component *ngTemplateOutlet="newFolder"></ng-component>
</ng-container>
<ng-container *ngIf="dataReady && _mydata.selection == 'update-folder'">
  <ng-component *ngTemplateOutlet="updateFolder"></ng-component>
</ng-container>


<ng-template #newFolder>
  <div class="w-full" mat-dialog-title>
    <div class="text-lg"> Add a {{_mydata.selection == 'new-folder' ? 'Expense/Project' : 'Expense/Project'}}</div>
    <div class="text-secondary"></div>
    <!-- Error Message -->
    <kod-alert class="mt-4 w-full" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type"
      [@shake]="alert.type === 'error'">
      <div class="text-right">
        {{alert.message}}
      </div>
    </kod-alert>
  </div>
  <div mat-dialog-content>
    <!-- Form -->
    <form [formGroup]="expenseFormGroup" class="w-120">
      <!-- Expense Nickname -->
      <div class="col-span-4 vstep:col-span-2">
        <mat-form-field class="kod-mat-no-subscript w-full" [floatLabel]="'always'">
          <mat-label>{{formSet.new['1'].purpose}}</mat-label>
          <mat-icon svgIcon="heroicons_solid:folder" class="icon-size-5" matPrefix></mat-icon>
          <input [formControlName]="formSet.new['1'].name" [name]="formSet.new['1'].purpose" matInput>
        </mat-form-field>
      </div>
      <!-- Expense Description -->
      <div class="col-span-4  mt-6  vstep:col-span-2">
        <mat-form-field class="kod-mat-no-subscript w-full" [floatLabel]="'always'">
          <mat-label>{{formSet.new['2'].purpose}}</mat-label>
          <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:document-text'" matPrefix>
          </mat-icon>
          <input [formControlName]="formSet.new['2'].name" [name]="formSet.new['2'].purpose" matInput>
        </mat-form-field>
      </div>
      <!-- Buttons -->
      <div class="flex items-end mt-6 gap-4 justify-end mb-0.5 relative w-full col-span-4 vstep:col-span-2">
        <!--Cancel Button-->
        <button class="btn btn-outline outline-red-600 " [color]="warn" mat-flat-button type="button"
          [mat-dialog-close]="true">
          <div class="flex justify-center items-center">
            <mat-icon class="icon-size-6 -ml-2" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
            <div class="ml-1">Cancel</div>
          </div>
        </button>
        <!--Add Button-->
        <button class="btn" mat-flat-button type="button" (click)="submit()" [disabled]="buttonSubmit"
          [color]="'primary'">
          <div class="flex justify-center items-center">
            <mat-icon class="icon-size-6 -ml-2" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
            <div class="ml-1">Create {{_mydata.selection == 'new-folder' ? 'Expense/Project' : 'Expense/Project'}}</div>
          </div>
        </button>
      </div>
    </form>
  </div>
</ng-template>


<ng-template #updateFolder>
  <div class="w-full" mat-dialog-title>
    <div class="text-lg"> Update {{_mydata.editData.folderName }}</div>
    <div class="text-secondary"></div>
    <!-- Error Message -->
    <kod-alert class="mt-4 w-full" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false" [type]="alert.type"
      [@shake]="alert.type === 'error'">
      <div class="text-right">
        {{alert.message}}
      </div>
    </kod-alert>
  </div>
  <div mat-dialog-content>
    <!-- Form -->
    <form [formGroup]="expenseFormGroup" class="w-120">
      <!-- Expense Nickname -->
      <div class="col-span-4 vstep:col-span-2">
        <mat-form-field class="kod-mat-no-subscript w-full" [floatLabel]="'always'">
          <mat-label>{{formSet.new['1'].purpose}}</mat-label>
          <mat-icon svgIcon="heroicons_solid:folder" class="icon-size-5" matPrefix></mat-icon>
          <input [formControlName]="formSet.new['1'].name" [name]="formSet.new['1'].purpose" matInput>
        </mat-form-field>
      </div>
      <!-- Expense Description -->
      <div class="col-span-4  mt-6  vstep:col-span-2">
        <mat-form-field class="kod-mat-no-subscript w-full" [floatLabel]="'always'">
          <mat-label>{{formSet.new['2'].purpose}}</mat-label>
          <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:document-text'" matPrefix>
          </mat-icon>
          <input [formControlName]="formSet.new['2'].name" [name]="formSet.new['2'].purpose" matInput>
        </mat-form-field>
      </div>
      <!-- Buttons -->
      <div class="flex items-end mt-6 gap-4 justify-end mb-0.5 relative w-full col-span-4 vstep:col-span-2">
        <!--Cancel Button-->
        <button class="btn btn-outline outline-red-600 " [color]="warn" mat-flat-button type="button"
          [mat-dialog-close]="true">
          <div class="flex justify-center items-center">
            <mat-icon class="icon-size-6 -ml-2" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>
            <div class="ml-1">Cancel</div>
          </div>
        </button>
        <!--Add Button-->
        <button class="btn" mat-flat-button type="button" (click)="submit()" [disabled]="buttonSubmit"
          [color]="'primary'">
          <div class="flex justify-center items-center">
            <mat-icon class="icon-size-6 -ml-2" [svgIcon]="'heroicons_solid:plus-circle'"></mat-icon>
            <div class="ml-1">Update {{_mydata.selection == 'new-folder' ? 'Expense/Project' : 'Expense/Project'}}</div>
          </div>
        </button>
      </div>
    </form>
  </div>
</ng-template>
