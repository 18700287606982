import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalsControllerModule } from './modals/modals.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { KodCardModule } from '@kod/components/card';
import { LibExpenseModule } from '@lib/apps/expense/expense.module';
import { LibTodoModule } from '@lib/apps/todo/todo.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalsControllerModule

    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalsControllerModule,
        MatButtonModule,
        MatTableModule,
        MatIconModule,
        MatCheckboxModule,
        MatTooltipModule,
        KodCardModule,
        LibTodoModule,
        LibExpenseModule,
        ModalsControllerModule
    ],
})
export class SharedModule
{
}
