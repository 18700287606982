import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { KodScrollbarModule } from '@kod/directives/scrollbar/public-api';
import { KodHorizontalNavigationBasicItemComponent } from '@kod/components/navigation/horizontal/components/basic/basic.component';
import { KodHorizontalNavigationBranchItemComponent } from '@kod/components/navigation/horizontal/components/branch/branch.component';
import { KodHorizontalNavigationDividerItemComponent } from '@kod/components/navigation/horizontal/components/divider/divider.component';
import { KodHorizontalNavigationSpacerItemComponent } from '@kod/components/navigation/horizontal/components/spacer/spacer.component';
import { KodHorizontalNavigationComponent } from '@kod/components/navigation/horizontal/horizontal.component';
import { KodVerticalNavigationAsideItemComponent } from '@kod/components/navigation/vertical/components/aside/aside.component';
import { KodVerticalNavigationBasicItemComponent } from '@kod/components/navigation/vertical/components/basic/basic.component';
import { KodVerticalNavigationCollapsableItemComponent } from '@kod/components/navigation/vertical/components/collapsable/collapsable.component';
import { KodVerticalNavigationDividerItemComponent } from '@kod/components/navigation/vertical/components/divider/divider.component';
import { KodVerticalNavigationGroupItemComponent } from '@kod/components/navigation/vertical/components/group/group.component';
import { KodVerticalNavigationSpacerItemComponent } from '@kod/components/navigation/vertical/components/spacer/spacer.component';
import { KodVerticalNavigationComponent } from '@kod/components/navigation/vertical/vertical.component';

@NgModule({
    declarations: [
        KodHorizontalNavigationBasicItemComponent,
        KodHorizontalNavigationBranchItemComponent,
        KodHorizontalNavigationDividerItemComponent,
        KodHorizontalNavigationSpacerItemComponent,
        KodHorizontalNavigationComponent,
        KodVerticalNavigationAsideItemComponent,
        KodVerticalNavigationBasicItemComponent,
        KodVerticalNavigationCollapsableItemComponent,
        KodVerticalNavigationDividerItemComponent,
        KodVerticalNavigationGroupItemComponent,
        KodVerticalNavigationSpacerItemComponent,
        KodVerticalNavigationComponent
    ],
    imports     : [
        CommonModule,
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        KodScrollbarModule
    ],
    exports     : [
        KodHorizontalNavigationComponent,
        KodVerticalNavigationComponent
    ]
})
export class KodNavigationModule
{
}
