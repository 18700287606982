<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- ----------------------------------------------------------------------------------------------------- -->
<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- Layout with horizontal navigation -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<modern-layout *ngIf="layout === 'modern'"></modern-layout>

<!-- ----------------------------------------------------------------------------------------------------- -->

<settings *ngIf="false" ></settings>
<section style="
    position: fixed;
    bottom: 1px;
    right: 0px;
    z-index: 1000;
    padding: 4px;
    color: black;
    background-color: #e8e8eb;
    text-transform: capitalize;
    width: 87px;
    font-weight: 100;
" >Version 1.2</section>
