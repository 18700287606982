import { NgModule } from '@angular/core';
import { KodScrollbarDirective } from '@kod/directives/scrollbar/scrollbar.directive';

@NgModule({
    declarations: [
        KodScrollbarDirective
    ],
    exports     : [
        KodScrollbarDirective
    ]
})
export class KodScrollbarModule
{
}
