import { HttpClient, HttpEvent } from '@angular/common/http';
import { Inject, Injectable, isDevMode } from '@angular/core';
import { AppHttpClient } from '@lib/http/app-http-client.service';
import { KodBoxService } from 'ngx-kodbox';
import { firstValueFrom, map, Observable } from 'rxjs';

/**
 * User Library Prototype
 *  - This is meant to unify the user process of the application
 *
 * (Prototype Library API)
 */
@Injectable({
  providedIn: 'root'
})
export class UserLibService {

  private libraryEnabled: boolean = true;

  constructor(
    private http: HttpClient,
    private anotherHttp: AppHttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private _wild: KodBoxService,
  ) {

    this._wild.setLambda("HTTP", http);
    this._wild.set("baseUrl", baseUrl);
    /* This is a check to see if the library is enabled. */
    // first thing check if the library is allowed
    if (!isDevMode()) {
      const checkLibStatus = async () => { this.libraryEnabled = await this.libEnabled(); };
      checkLibStatus();
    }
    if (isDevMode()) {
      console.log('[ User library Service is ' + (this.libraryEnabled ? 'enabled' : 'disabled') + ' ]');
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ User Library Global Controller | Begins
  // -----------------------------------------------------------------------------------------------------


  // Ask the server if user library should be enabled | Begin
  private requestIfUserLibraryIsEnabled(): Observable<boolean> {
    return new Observable(observer => { true });  // Later down the line, this will be replaced with a call to the API
  }
  private async libEnabled(): Promise<boolean> {
    return await firstValueFrom(this.requestIfUserLibraryIsEnabled());
  }
  //
  //


  // -----------------------------------------------------------------------------------------------------
  // @ User Library Global Controller | Ends
  // -----------------------------------------------------------------------------------------------------


  //
  //


  // -----------------------------------------------------------------------------------------------------
  // @ User Library Data Information | Begins
  // -----------------------------------------------------------------------------------------------------

  //
  private callApiForCreateUser(user: any): Observable<any> {
    return this._wild.lambda("HTTP").post('api/account/create/user', user);
  }
  public async createUser(user: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForCreateUser(user)).catch(err => {
        if (isDevMode()) console.log("[ User library Service ] - Create User Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will create a user and return the user object
   */
  public async createUserStrict(user: any): Promise<any> {
    return await this.createUser(user);
  }
  // by pass async
  public createNewUser(user: any): Observable<any> {
    return this.callApiForCreateUser(user);
  }
  //
  //

  //
  private callApiForGetSingleUser(): Observable<any> {
    return this._wild.lambda("HTTP").get('api/user/get/single');
  }
  public async getSingleUser(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForGetSingleUser()).catch(err => {
        if (isDevMode()) console.log("[ User library Service ] - Get User Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will a single user and return the user object
   */
  public async getSingleUserStrict(): Promise<any> {
    return await this.getSingleUser();
  }
  //
  //



  //
  private callApiForGetUserList(migration: boolean = false): Observable<any> {
    if (migration) {
      return this._wild.lambda("HTTP").get('api/todo/update/migration');
    } else {
      return this._wild.lambda("HTTP").get('api/User/get/all');
    }
  }
  public async getUserList(migration: boolean = false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForGetUserList(migration)).catch(err => {
        if (isDevMode()) console.log("[ User library Service ] - Get User List Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will get the list of todos from the system
   */
  public async getUserListStrict(migration: boolean = false): Promise<any> {
    return await this.getUserList(migration);
  }

}


