import { NgModule } from '@angular/core';
import { KodSplashScreenService } from '@kod/services/splash-screen/splash-screen.service';

@NgModule({
    providers: [
        KodSplashScreenService
    ]
})
export class KodSplashScreenModule
{
    /**
     * Constructor
     */
    constructor(private _kodSplashScreenService: KodSplashScreenService)
    {
    }
}
