import { NgModule } from '@angular/core';
import { KodMediaWatcherService } from '@kod/services/media-watcher/media-watcher.service';

@NgModule({
    providers: [
        KodMediaWatcherService
    ]
})
export class KodMediaWatcherModule
{
    /**
     * Constructor
     */
    constructor(private _kodMediaWatcherService: KodMediaWatcherService)
    {
    }
}
