import { Inject, Injectable, isDevMode } from '@angular/core';
import { firstValueFrom, from, map, Observable } from 'rxjs';
import _ from 'lodash';
import { HttpClient, HttpParams } from '@angular/common/http';
import { KodBoxService } from 'ngx-kodbox';

/**
 * Expense Library Prototype
 *  - This is meant to unify the Expense process of the application
 *
 * (Prototype Library API)
 */
@Injectable({
  providedIn: 'root'
})
export class ExpenseLibService {

  private libraryEnabled: boolean = true;

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private _wild: KodBoxService,
  ) {

    this._wild.setLambda("HTTP", http);
    this._wild.set("baseUrl", baseUrl);
    // first thing check if the library is allowed
    if (!isDevMode()) {
      const checkLibStatus = async () => { this.libraryEnabled = await this.libEnabled(); };
      checkLibStatus();
    }
    if (isDevMode()) {
      console.log('[ Expense library Service is ' + (this.libraryEnabled ? 'enabled' : 'disabled') + ' ]');
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Expense Library Global Controller | Begins
  // -----------------------------------------------------------------------------------------------------


  // Ask the server if Expense library should be enabled | Begin
  private requestIfUserLibraryIsEnabled(): Observable<boolean> {
    return new Observable(observer => { true });  // Later down the line, this will be replaced with a call to the API
  }
  private async libEnabled(): Promise<boolean> {
    return await firstValueFrom(this.requestIfUserLibraryIsEnabled());
  }
  //
  //


  // -----------------------------------------------------------------------------------------------------
  // @ Expense Library Global Controller | Ends
  // -----------------------------------------------------------------------------------------------------


  //
  //

  public sayHello() {
    if (this.libraryEnabled) {
      console.log('Hello from the Expense library');
    }
  }




  // -----------------------------------------------------------------------------------------------------
  // @ Expense Library Data Information | Begins
  // -----------------------------------------------------------------------------------------------------


    // Floater Upkeep
    private callApiForUpKeepExpenseFloater(): Observable<any> {
      // this is a Floater/Expense Action
      return this._wild.lambda("HTTP").get('api/expense/floater/upkeep');
    }
    public async upKeepExpenseFloater(): Promise<any> {
      return new Promise(async (resolve, reject) => {
        const call = await firstValueFrom(this.callApiForUpKeepExpenseFloater()).catch(err => {
          if (isDevMode()) console.log("[ Expense library Service ] - Create Expense Floater Error");
          if (isDevMode()) console.log(err);
          return reject(err);
        });
        return resolve(call);
      });
    }
    /**
     * Function will perform a upkeep on the system
     */
    public async upKeepExpenseFloaterStrict(): Promise<any> {
      return await this.upKeepExpenseFloater();
    }
    //
    //



  // Floater Create
  private callApiForCreateExpenseFloater(data: any): Observable<any> {
    // this is a Floater
    return this._wild.lambda("HTTP").post('api/expense/floater/create', data);
  }
  public async createExpenseFloater(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForCreateExpenseFloater(data)).catch(err => {
        if (isDevMode()) console.log("[ Expense library Service ] - Create Expense Floater Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will create a todo in the system
   */
  public async createExpenseFloaterStrict(data: any): Promise<any> {
    return await this.createExpenseFloater(data);
  }
  //
  //

  // Expense Create
  private callApiForCreateExpense(data: any): Observable<any> {
    // this is a Expense
    return this._wild.lambda("HTTP").post('api/expense/create', data);
  }
  public async createExpense(data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForCreateExpense(data)).catch(err => {
        if (isDevMode()) console.log("[ Expense library Service ] - Create Expense Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will create a expense in the system
   */
  public async createExpenseStrict(data: any): Promise<any> {
    return await this.createExpense(data);
  }
  //
  //


  // Floater
  private callApiForGetAllExpenseFloaters(parent:boolean=false): Observable<any> {
    // this is a Expense
    if(parent){
      return this._wild.lambda("HTTP").get('api/expense/floater/parents');
    }else{
      return this._wild.lambda("HTTP").get('api/expense/floater/getall');
    }
  }
  public async getAllExpenseFloaters(parent:boolean=false): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForGetAllExpenseFloaters(parent)).catch(err => {
        if (isDevMode()) console.log("[ Expense library Service ] - Get All Expense Floaters Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will get all the Floaters in the system
   */
  public async getAllExpenseFloatersStrict(parent:boolean=false): Promise<any> {
    return await this.getAllExpenseFloaters(parent);
  }
  //
  //


  //
  private callApiForGetExpenseFloaterByGPX(gpx: string): Observable<any> {
    // this is a Expense
    return this._wild.lambda("HTTP").get('api/expense/floater/get/' + gpx);
  }
  public async getExpenseFloaterByGPX(gpx: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForGetExpenseFloaterByGPX(gpx)).catch(err => {
        if (isDevMode()) console.log("[ Expense library Service ] - Get Expense Floater By GPX Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will get a Floater by GPX (aka the ID)
   */
  public async getExpenseFloaterByGPXStrict(gpx: string): Promise<any> {
    return await this.getExpenseFloaterByGPX(gpx);
  }
  //


  //
  private callApiForUpdateExpenseFloaterByGPX(gpx: string, data: any): Observable<any> {
    // this is a Expense
    return this._wild.lambda("HTTP").patch('api/expense/floater/update/' + gpx, data);
  }
  public async updateExpenseFloaterByGPX(gpx: string, data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForUpdateExpenseFloaterByGPX(gpx, data)).catch(err => {
        if (isDevMode()) console.log("[ Expense library Service ] - Update Expense Floater By GPX Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will update a Floater by GPX (aka the ID)
   */
  public async updateExpenseFloaterByGPXStrict(gpx: string, data: any): Promise<any> {
    return await this.updateExpenseFloaterByGPX(gpx, data);
  }
  //
  //


  //
  private callApiForDeleteExpenseFloaterByGPX(gpx: string): Observable<any> {
    // this is a Expense
    return this._wild.lambda("HTTP").delete('api/expense/floater/delete/' + gpx);
  }
  public async deleteExpenseFloaterByGPX(gpx: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForDeleteExpenseFloaterByGPX(gpx)).catch(err => {
        if (isDevMode()) console.log("[ Expense library Service ] - Delete Expense Floater By GPX Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will delete a Floater by GPX (aka the ID)
   */
  public async deleteExpenseFloaterByGPXStrict(gpx: string): Promise<any> {
    return await this.deleteExpenseFloaterByGPX(gpx);
  }
  //
  //



  //
  private callApiForDeleteExpenseByGpx(gpx: string): Observable<any> {
    // this is a Expense
    return this._wild.lambda("HTTP").delete('api/expense/delete/' + gpx);
  }
  public async deleteExpenseByGPX(gpx: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForDeleteExpenseByGpx(gpx)).catch(err => {
        if (isDevMode()) console.log("[ Expense library Service ] - Delete Expense By GPX Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will delete a Floater by GPX (aka the ID)
   */
  public async deleteExpenseByGPXStrict(gpx: string): Promise<any> {
    return await this.deleteExpenseByGPX(gpx);
  }
  //
  //




  //
  private callApiForGetAllExpensesViaGPX(folderGPX:string): Observable<any> {
    // this is a Expense
    return this._wild.lambda("HTTP").get('api/expense/' + folderGPX + '/all');
  }
  public async getAllExpensesViaGPX(folderGPX:string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForGetAllExpensesViaGPX(folderGPX)).catch(err => {
        if (isDevMode()) console.log("[ Expense library Service ] - Get All Expenses Via GPX Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will get all the Expenses for a given folder
   */
  public async getAllExpensesViaGPXStrict(folderGPX:string): Promise<any> {
    return await this.getAllExpensesViaGPX(folderGPX);
  }
  //
  //


  //
  private callApiForAssignFolderToFolder(folderChildGPX:string, folderParentGPX:string): Observable<any> {
    // this is a Expense
    return this._wild.lambda("HTTP").patch('api/expense/floater/assign/' + folderChildGPX + '/to/' + folderParentGPX);
  }
  public async assignFolderToFolder(folderChildGPX:string, folderParentGPX:string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForAssignFolderToFolder(folderChildGPX, folderParentGPX)).catch(err => {
        if (isDevMode()) console.log("[ Expense library Service ] - Assign Folder To Folder Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will assign a folder to a folder
   */
  public async assignFolderToFolderStrict(folderChildGPX:string, folderParentGPX:string): Promise<any> {
    return await this.assignFolderToFolder(folderChildGPX, folderParentGPX);
  }
  //
  //



  //
  private callApiForUpdateSingleExpenseByGPX(gpx: string, data: any): Observable<any> {
    // this is a Expense
    return this._wild.lambda("HTTP").patch('api/expense/update/' + gpx, data);
  }
  public async updateSingleExpenseByGPX(gpx: string, data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const call = await firstValueFrom(this.callApiForUpdateSingleExpenseByGPX(gpx, data)).catch(err => {
        if (isDevMode()) console.log("[ Expense library Service ] - Update Single Expense By GPX Error");
        if (isDevMode()) console.log(err);
        return reject(err);
      });
      return resolve(call);
    });
  }
  /**
   * Function will update a Floater by GPX (aka the ID)
   */
  public async updateSingleExpenseByGPXStrict(gpx: string, data: any): Promise<any> {
    return await this.updateSingleExpenseByGPX(gpx, data);
  }
  //
  //




  // -----------------------------------------------------------------------------------------------------


}

