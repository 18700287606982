import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '@app/core/auth/auth.service';
import { AuthLibActions } from '@lib/security/auth/lib-auth-actions';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard
{
  /**
   * Constructor
   */
  constructor(
    private _authActs: AuthLibActions,
    private _router: Router
  )
  {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
  {
    return this._check();
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this._check();
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
  {
    return this._check();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the authenticated status
   *
   * @private
   */
  private _check(): Observable<boolean>
  {
    // Check the authentication status
    return this._authActs.check()
      .pipe(
        switchMap((authenticated) => {
          // console.log('NoAuthGuard._check() authenticated: ' + authenticated);

          // If the user is authenticated...
          if ( authenticated )
          {
            // console.log(this._router.url)
            // console.log("NoAuthGuard._check() authenticated: " + authenticated);
            // console.log("sending to root");
            // Redirect to the root
            if (this._router.url == "/home")
            {

              this._router.navigate(['/dashboard']);
            }else {
                this._router.navigate(['']);
            }

            // Prevent the access
            return of(false);
          }

          // Allow the access
          return of(true);
        })
      );
  }
}
