import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthLibActions } from '@lib/security/auth/lib-auth-actions';
/**
 *  Security Interceptor (Middleware)
 *  - JwtInBound - Interceptor
 *  - Gets the access token and assigns it for later use
 */
@Injectable({ providedIn: 'root' })
export class SecurityCheckInbound implements HttpInterceptor {
    constructor(
        private _authActs:AuthLibActions) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(map(event => {
            if (event instanceof HttpResponse && (event.status === 200 || event.status === 201)) {
              if(event.body && event.body.tokToken){
                this._authActs.evaluateAccessToken(event.body);
              }
            }
            return event;
        }));
    }
}
