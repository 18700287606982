import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { KodConfirmationConfig, KodConfirmationService } from '@kod/services/confirmation';
import { ExpenseModalComponent } from '@lib/apps/expense/extend/_modal/expense.component';
import { ExpenseLibService } from '@lib/apps/expense/lib/lib-expense';
import { KodBoxService } from 'ngx-kodbox';

@Component({
  selector: 'expense',
  templateUrl: './expense.component.html',
  styleUrls: ['./expense.component.scss']
})
export class ExpenseComponent implements OnInit {


  private _component = {
    title: 'Expenses',
    description: 'Add, edit, delete Expenses.',
    icon: 'shipment',
    data: {
      ready: false,
      floaters: [], // this will hold all of our parent floaters/folders
      all: [], // this will hold all of our floaters over all
    },
    selectedExpense: false,
    selectedType: 'floater',
    selectedExpenseData: null,
    list: [],
    listActions:false
  }

  private skinConfirm: KodConfirmationConfig =
    {
      title: "Delete Entire Expense ?",
      message: "<br />Are you sure you want to delete this Expense? <br /><span class=\"font-medium\">This action cannot be undone!</span>",
      icon: {
        show: true,
        name: "heroicons_outline:exclamation",
        color: "warn"
      },
      actions: {
        confirm: {
          show: true,
          label: "Delete",
          color: "warn"
        },
        cancel: {
          show: true,
          label: "Cancel"
        }
      },
      dismissible: true
    }

  get component() {
    return this._component;
  }

  get floaters() {
    return this._component.data.floaters;
  }

  constructor(
    private _dialog: MatDialog,
    private _expense: ExpenseLibService,
    private _kodConfirmationService: KodConfirmationService,
    private _wild: KodBoxService
  ) { }

  ngOnInit() {
    this.init();
  }

  private async init() {
    await this._expense.getAllExpenseFloatersStrict().then(res => {
      // console.log(res);
      this._component.data.all = res.all;
      this._component.data.floaters = res.parents;
    }).catch(err => {
      console.log(err);
    }).finally(async () => {
      await this._wild.setAsync('expense', this._component, false, true);
      // bind local functions
      this._wild.setLambda('selectFloat', this.selectFloat.bind(this));
      this._wild.setLambda('deleteFloat', this.deleteFloat.bind(this));
      this._wild.setLambda('openExpenseModal', this.openExpenseModal.bind(this));

    });
  }

  private async performDeleteFloat(floater) {
    await this._expense.deleteExpenseFloaterByGPXStrict(floater.folderGPX).then(res => {
      this.init();
    }).catch(err => {
      console.log(err);
    });
  }

  deleteFloat(floater): void {
    // Open the dialog and save the reference of it
    const dialogRef = this._kodConfirmationService.open(this.skinConfirm);
    // Subscribe to afterClosed from the dialog reference
    dialogRef.afterClosed().subscribe(async (result) => {
      console.log(result);
      if (result == 'confirmed') {
        await this.performDeleteFloat(floater);
      } else {
        return;
      }
    });
  }


  async selectFloat(floater) {
    this._component.selectedExpense = true;
    this._component.selectedExpenseData = floater;
  }

  async upkeepTheList(){
    await this._expense.upKeepExpenseFloaterStrict().then().finally(async()=>{
      await this.init();
    });
  }

  openExpenseModal(str: string, data?: any) {
    const dialogConfig = new MatDialogConfig<any>();
    switch (str) {
      case 'new-folder':
        dialogConfig.data = {
          selection: "new-folder",
          editData: data || {}
        };
        const createDialog = this._dialog.open(ExpenseModalComponent, dialogConfig);
        createDialog.afterClosed().subscribe(result => {
          if (result.event == 'save') {
            this._component.data.floaters = this._component.data.floaters.concat(result.data);
          }
        });
        break;
      case 'update-folder':
        dialogConfig.data = {
          selection: "update-folder",
          editData: data || {}
        };
        const createDialogUpdate = this._dialog.open(ExpenseModalComponent, dialogConfig);
        createDialogUpdate.afterClosed().subscribe(async result => {
          if (result.event == 'update') {
            // update the data
            await this.init();
          }
        });
        break;
      default:
        console.log("No dialog exists for query")
    }
  }

}
